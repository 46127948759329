import config from '../configLoader'
import '../utils/momentToJSONConverter'

export async function fetchData(httpMethod: string, url: string, data: any) {
  let _navigator: Record<string, any> = {}
  for (const i in navigator) {
    _navigator[i] = (navigator as any)[i]
  }

  const dataWithFingerprint = { ...data, fingerprint: btoa(JSON.stringify(_navigator)) }
  try {
    const response = await fetch(`${config.baseUrl}${url}`, {
      method: httpMethod,
      body: JSON.stringify(dataWithFingerprint),
      headers: {
        'Content-Type': 'application/json',
        'Api-Key': config.apiKey,
      },
    })

    if (response.status === 201) {
      return response.json().then((data) => {
        return data
      })
    } else if (response.status >= 500 && response.status < 600) {
      const data = {
        data: {
          errorMessage: `We're sorry, but our server encountered an error. Please try again in a few minutes.
          \n
          Status ${response.status}`,
        },
      }
      return data
    }

    return response.json()
  } catch (error) {
    console.error('Error fetching data:', error)
    throw error
  }
}

export async function loginUser(postData: any) {
  const data = await fetchData('POST', '/public-auth', postData)
  return data
}
