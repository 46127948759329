import React, { createContext, useContext, useState, ReactNode } from 'react'
import { Role, Roles } from '../models/Role'

// Define the shape of the context value
interface PatientDataContextValue {
  verificationCodes: string
  setVerificationCodes: React.Dispatch<React.SetStateAction<string>>
  patientId: number
  setPatientId: React.Dispatch<React.SetStateAction<number>>
  facilityId: number
  setFacilityId: React.Dispatch<React.SetStateAction<number>>
  contractId?: number | string
  setContractId: React.Dispatch<React.SetStateAction<number | string | undefined>>
  sessionId: string
  setSessionId: React.Dispatch<React.SetStateAction<string>>
  role: Role
  phoneNumber: string
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>
}

const PatientDataContext = createContext<PatientDataContextValue | undefined>(undefined)

export const usePatientDataContext = (): PatientDataContextValue => {
  const context = useContext(PatientDataContext)
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider')
  }
  return context
}
// move this somewhere else
interface ContextProviderProps {
  children: ReactNode
}

export const PatientDataProvider: React.FC<ContextProviderProps> = ({ children }) => {
  const [verificationCodes, setVerificationCodes] = useState<string>('')
  const [patientId, setPatientId] = useState<number>(0)
  const [facilityId, setFacilityId] = useState<number>(0)
  const [contractId, setContractId] = useState<number | string | undefined>(undefined)
  const [sessionId, setSessionId] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [role, setRole] = useState<Role>(Roles.PATIENT)

  const contextValue: PatientDataContextValue = {
    verificationCodes,
    setVerificationCodes,
    patientId,
    setPatientId,
    facilityId,
    setFacilityId,
    contractId,
    setContractId,
    sessionId,
    setSessionId,
    role,
    phoneNumber,
    setPhoneNumber
  }

  return <PatientDataContext.Provider value={contextValue}>{children}</PatientDataContext.Provider>
}
