/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from "react";
import { useAppErrorContext } from "../../contexts/AppErrorContext";
import { FieldValues, FormProvider, useForm } from "react-hook-form";
import { Box, Button } from "@mui/material";
import { SignatureFormProvider } from "../widgets/SignatureWidget/SignatureFormContext";
import { Loader } from "../Loader";
import { useAppContext } from "../../contexts/AppContext";

export type DocumentFormProps = {
  parsedData: React.JSX.Element | React.JSX.Element[];
  onFormDataSubmit: (formData: FieldValues) => void;
};

export const DocumentForm: FC<DocumentFormProps> = ({
  parsedData,
  onFormDataSubmit,
}) => {
  const { setErrorMessage, openErrorDialog } = useAppErrorContext();
  const { loading } = useAppContext();

  const formMethods = useForm({ reValidateMode: "onChange" });
  const {
    handleSubmit,
    formState: { submitCount, isValid },
  } = formMethods;

  useEffect(() => {
    if (!isValid && submitCount > 0) {
      openErrorDialog(
        "The form is filled incorrectly or is not filled. Please try again.",
      );
    } else {
      setErrorMessage("");
    }
  }, [isValid, submitCount]);

  return (
    <>
      <SignatureFormProvider>
        <FormProvider {...formMethods}>
          <form noValidate onSubmit={handleSubmit(onFormDataSubmit)}>
            <>
              {parsedData}
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth={false}
                  style={{ marginTop: "1rem", width: "144px" }}
                  disabled={loading}
                >
                  Submit {loading && <Loader />}
                </Button>
              </Box>
            </>
          </form>
        </FormProvider>
      </SignatureFormProvider>
    </>
  );
};
