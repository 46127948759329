import { Component, OnInit, Input } from '@angular/core'
import { LhcDataService } from '../../lib/lhc-data.service'
import { ConditionalExpr } from '@angular/compiler'

@Component({
  selector: 'lhc-input',
  templateUrl: './lhc-input.component.html',
  styleUrls: ['./lhc-input.component.css'],
})
export class LhcInputComponent {
  // Handles the input fields for simple data types, such as integer, decimal,
  // string, and etc.
  @Input() item

  constructor(public lhcDataService: LhcDataService) {}

  ngOnInit(): void {
    document.addEventListener('updateInputValue', this.handleUpdateInputValue.bind(this))
  }

  ngOnDestroy(): void {
    document.removeEventListener('updateInputValue', this.handleUpdateInputValue.bind(this))
  }

  handleUpdateInputValue(event: CustomEvent) {
    const payload = event.detail
    const newValue = payload.newValue
    const targetComponentId = payload.componentId

    if (targetComponentId !== this.item._elementId) return

    this.onModelChange(newValue)
  }

  onModelChange(value) {
    let prevValue = this.item.value
    this.item.value = value
    this.lhcDataService.onItemValueChange(this.item, this.item.value, prevValue)
  }
}
