import { FC } from "react";
import { UIWidgetProps } from "../@types/UIWidget.types";
import { FormController } from "../FormController/FormController";
import { InputWidget } from "./InputWidget";


export const InputFormWidget: FC<UIWidgetProps> = ({ props }) => {
  return (
    <FormController field={props}>
      <InputWidget props={props} />
    </FormController>
  );
};
