import { css } from '@emotion/react'

const styles = {
  pickerInputHover: css`
    .ant-picker-input:hover input[readonly='true'] ~ .ant-picker-clear {
      display: none;
    }
  `,
  pickerReadonly: css`
    .ant-picker[readonly='true'] {
      border-color: rgb(206, 213, 217);
    }
  `,
};

export default styles;