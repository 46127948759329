import React, { createContext, useContext, useState, ReactNode } from 'react';

// Define the shape of the context value
interface AppErrorContextValue {
    errorMessage: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
    openErrorModal: boolean;
    openErrorDialog: (message: string) => void;
    handleCloseErrorModal: () => void;
}

// Create a context for the mobile state
const AppErrorContext = createContext<AppErrorContextValue | undefined>(undefined);

export const useAppErrorContext = (): AppErrorContextValue => {
    const context = useContext(AppErrorContext);
    if (!context) {
        throw new Error('useAppErrorContext must be used within an ErrorProvider');
    }
    return context;
};

interface ErrorProviderProps {
    children: ReactNode;
}

export const ErrorProvider: React.FC<ErrorProviderProps> = ({ children }) => {
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const openErrorDialog = (message: string) => {
        setErrorMessage(message);
        setOpenErrorModal(true);
    };

    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const contextValue: AppErrorContextValue = {
        errorMessage,
        setErrorMessage,
        openErrorModal,
        openErrorDialog,
        handleCloseErrorModal,
    };

    return <AppErrorContext.Provider value={contextValue}>{children}</AppErrorContext.Provider>;
};
