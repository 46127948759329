import { css } from '@emotion/react';

const styles = {
  formLabel: css`
    .inputWidget label.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl:not(.MuiFormLabel-filled) {
      transform: translate(15px, -50%);
      position: absolute;
      top: 50%;
      width: 100%;
    }
  `,
  inputWidget: css`
    .inputWidget {
      position: relative;
      display: inline-block;
    }
  `,
  formControl: css`
    .inputWidget .MuiFormControl-root.MuiFormControl-marginNormal.MuiTextField-root {
      width: 100%;
    }
  `,
};

export default styles;
