import { FC } from 'react'
import { UIWidgetProps } from '../@types/UIWidget.types'
import { FormController } from '../FormController/FormController'
import { DatePickerWidget } from './DatePickerWidget'

export const DatePickerFormWidget: FC<UIWidgetProps> = ({ props }) => {
  return (
    <FormController field={props}>
      <DatePickerWidget props={props} />
    </FormController>
  )
}
