import React, { useEffect, useRef, useState } from 'react'
import { useAppContext } from '../../contexts/AppContext'
import { useAppErrorContext } from '../../contexts/AppErrorContext'
import { usePatientDataContext } from '../../contexts/PatientDataContext'
import { fetchData } from '../../api/apiService'
import { UNEXPECTED_ERROR_MSG } from '../stringConstants'
import { Step } from '../types'
import PassportControls from './PassportControls'
import { BasePassportPickerLayout } from './BasePassportPickerLayout'

interface PassportCaptureProps {
  setStep: (step: Step) => void
}

const PassportCaptureComponent: React.FC<PassportCaptureProps> = ({ setStep }) => {
  const videoRef = useRef<any>(null)
  const { setLoading } = useAppContext()
  const { openErrorDialog } = useAppErrorContext()
  const { sessionId, verificationCodes, patientId, contractId, facilityId } =
    usePatientDataContext()
  const [imageSrc, setImageSrc] = useState<string>('')
  const [isSnap, setIsSnap] = useState<boolean>(false)
  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches)
  const [isFirstTimePhotoTaking, setIsFirstTimePhotoTaking] = useState(true)

  const orientationHandler = (event: any) => {
    setIsPortrait(event.matches)
  }

  useEffect(() => {
    const orientationQuery = window.matchMedia('(orientation: portrait)')
    orientationQuery.addEventListener('change', orientationHandler)
    return () => {
      orientationQuery.removeEventListener('change', orientationHandler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const startCamera = async () => {
    setImageSrc('')
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode: 'environment' },
      })
      if (videoRef.current) {
        videoRef.current.srcObject = stream
        setIsSnap(true)
      }
    } catch (error) {
      console.error('Error accessing camera:', error)
    }
  }

  const capture = () => {
    const canvas = document.createElement('canvas')
    if (videoRef.current) {
      const video = videoRef.current
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)
      const imageSrc = canvas.toDataURL('image/png')
      setImageSrc(imageSrc)
      setIsSnap(false)
      setIsFirstTimePhotoTaking(false)
    }
  }

  const handleConfirmFile = async () => {
    setLoading(true)

    let responseData: any
    try {
      responseData = await fetchData('POST', '/npp/post-passport', {
        sessionId: sessionId,
        verificationCode: verificationCodes,
        pid: patientId,
        contractId: contractId,
        facilityId: facilityId,
        photoIDs: [imageSrc],
      })
    } catch (error) {
      openErrorDialog(UNEXPECTED_ERROR_MSG)
    } finally {
      setLoading(false)
    }
    if (!responseData) return
    if (responseData.data.errorMessage) {
      openErrorDialog(responseData.data.errorMessage)
    } else {
      setStep(responseData.data.nextStep)
    }
  }

  return (
    <>
      <>
        <BasePassportPickerLayout
          videoOutput={{
            node: (
              <>
                <video
                  ref={videoRef}
                  hidden={!isSnap}
                  autoPlay
                  playsInline
                  className='passport__img'
                  style={{ maxHeight: '98%' }}
                />
              </>
            ),
            isVideoHidden: !isSnap,
          }}
          passportMedia={
            imageSrc
              ? {
                  mediaElement: (
                    <>
                      <img
                        src={imageSrc}
                        alt='takenPhoto'
                        className='passport__img'
                        style={{ maxHeight: '98%' }}
                      />
                    </>
                  ),
                  name: 'img',
                }
              : undefined
          }
          validationMessage={isPortrait && isSnap ? 'Please orient the device horizontally' : ''}
          passportControls={
            <PassportControls
              handleChooseAnotherFile={isSnap ? capture : startCamera}
              handleConfirmFile={handleConfirmFile}
              uploadBtnLabel={
                isSnap ? 'snap a frame' : isFirstTimePhotoTaking ? 'start camera' : 'retake a photo'
              }
              submitFileDisabled={!imageSrc}
            />
          }
        />
      </>
    </>
  )
}

export default PassportCaptureComponent
