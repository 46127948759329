/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react'
import parse from 'html-react-parser'
import { CSSTransition } from 'react-transition-group'

import { DocumentForm } from './DocumentForm'
import { getOptions, twigParser } from './DocumentForm.helper'
import { FieldValues } from 'react-hook-form'
import { DocumentResponse } from '../types'
import './WrapperForDocumentForm.css'
import { Box, Paper } from '@mui/material'
import documentStyles from './WrapperForDocumentFormStyles'
import { Global } from '@emotion/react'

export type DocumentFormProps = {
  documentResponse: DocumentResponse
  onFormDataSubmit: (formData: FieldValues) => void
  showComponent: boolean
  setShowComponent: React.Dispatch<React.SetStateAction<boolean>>
}

export const WrapperForDocumentForm: FC<DocumentFormProps> = ({
  documentResponse,
  onFormDataSubmit,
  showComponent,
  setShowComponent,
}) => {
  const { documentHTML, jsonData, id, title } = documentResponse
  const [data, setData] = useState<any>(null)
  const [parsedData, setParsedData] = useState<JSX.Element | JSX.Element[] | null>(null)
  const nodeRef = useRef(null)

  useEffect(() => {
    setShowComponent(true)
    const { html, widgetsProps } = twigParser(documentHTML || '', jsonData)

    const document = {
      id: id,
      html: html,
      widgets: widgetsProps,
      title: title,
    }
    setData(document)
  }, [])

  useEffect(() => {
    if (!data) return
    const options = getOptions(data.widgets)
    const parsedHTML = parse(data.html, options) as JSX.Element | JSX.Element[]
    setParsedData(parsedHTML)
  }, [data])

  return (
    <>
      <Global styles={documentStyles} />
      <CSSTransition
        nodeRef={nodeRef}
        in={showComponent}
        timeout={400}
        classNames='document'
        unmountOnExit
      >
        <Box ref={nodeRef}>
          <Paper
            elevation={3}
            style={{
              padding: '20px',
            }}
          >
            {parsedData && (
              <DocumentForm parsedData={parsedData} onFormDataSubmit={onFormDataSubmit} />
            )}
          </Paper>
        </Box>
      </CSSTransition>
    </>
  )
}
