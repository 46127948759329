import { HTMLReactParserOptions } from 'html-react-parser';
import Twig, { extendFilter } from 'twig';
import { GetFieldWidget } from '../widgets/widgetsFactory'

function parseAndUnescapeQuotsJson(jsonString: string): any {
  jsonString = jsonString.replace(/&quot;/g, '"');
  jsonString = jsonString.replace(/\\"/g, '"');
  return JSON.parse(jsonString);
}

export const twigParser = (
  html: string,
  formData: any
): { html: string; widgetsProps: any | null } => {
  const widgetsProps: Record<string, string> = {};

  Twig.extend(() => {
    extendFilter('field', (value, config) => {
      if (config && config.length) {
        //maybe change the layout over here
        let widgetProps = parseAndUnescapeQuotsJson(config[0]);
        let id = widgetProps.fieldId;
        if (!id) return value || '';

        widgetsProps[id] = widgetProps;
        return `<span id="${id}"></span>`;
      }

      return value || '';
    });
  });

  const template = Twig.twig({
    data: html
  });
  return { html: template.render({ ...formData }), widgetsProps };
};

export function getOptions(widgets: any): HTMLReactParserOptions  {
  const options: HTMLReactParserOptions = {
    replace: (domNode: any) => {
      if (
        !!widgets &&
        !!domNode &&
        domNode.attribs?.id &&
        widgets[domNode.attribs.id] &&
        widgets[domNode.attribs.id].fieldId === domNode.attribs.id
      ) {
        let props = {
          ...widgets[domNode.attribs.id],
          fullWidth: false,
          isDocumentWidget: true,
          isShowErrorMessage: true,
          disabled: false,
        }

        return (
          //@ts-ignore
          <div style={{ display: 'inline-block', width: '100%', minWidth: '140px', padding: '0 4px' }}>{<GetFieldWidget {...props} />}</div>
        )
      }
    },
  }
  return options
}

