import { FC, useEffect, useRef, useState } from 'react'

import { TextField } from '@mui/material'

import { TextareaWidgetProps } from '../@types/TextareaWidget.type'
import {
  getDocumentWidgetSX,
  getMaxRowsForTextArea,
  getTextFieldInputProps,
} from '../../../utils/stylesHelper'
import { UIWidgetProps } from '../@types/UIWidget.types'

export const TextareaWidget: FC<UIWidgetProps> = ({ props, cntrllrRenderProps }) => {
  const {
    type,
    label,
    fieldId,
    required,
    disabled,
    // value,
    rows,
    style,
    isDocumentWidget,
  } = props as TextareaWidgetProps
  const { onChange, value, fieldState } = cntrllrRenderProps ?? {}

  const inputRef = useRef<HTMLElement | null>(null)
  const [maxRows, setMaxRows] = useState<number>(1)

  const inputProps = getTextFieldInputProps()
  const sx = getDocumentWidgetSX(style)

  useEffect(() => {
    if (!inputRef.current) return
    const element = inputRef.current

    const maxRows = getMaxRowsForTextArea(element)
    setMaxRows(maxRows)
  }, [])

  return (
    <>
      <TextField
        inputRef={inputRef}
        id={fieldId}
        rows={rows || maxRows}
        type={type}
        value={value}
        error={!!fieldState?.error}
        margin='normal'
        required={required}
        onChange={onChange}
        disabled={disabled}
        sx={sx}
        inputProps={inputProps}
        multiline
        fullWidth
        label={!isDocumentWidget ? label : undefined}
        placeholder={isDocumentWidget && label !== 'undefined' ? label : undefined}
      />
    </>
  )
}
