import {
  Dialog,
} from '@mui/material'
import { useAppErrorContext } from '../../contexts/AppErrorContext'
import { FC } from 'react'
import { InformationDisplayer } from '../InformationDisplayer/InformationDisplayer'
import { Status } from '../types'

export const ModalError: FC = () => {
  const { errorMessage, openErrorModal, handleCloseErrorModal } = useAppErrorContext()
  return (
    <>
      {errorMessage && (
        <Dialog open={openErrorModal} onClose={handleCloseErrorModal}>
          <InformationDisplayer
            info={{
              status: Status.error,
              mainMessage: errorMessage,
            }}
            action={{ text: 'Close', clickHandler: handleCloseErrorModal }}
          />
        </Dialog>
      )}
    </>
  )
}
