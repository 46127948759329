import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { PromptInfo } from "../types";
import { Loader } from "../Loader";
import { useAppContext } from "../../contexts/AppContext";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useTheme } from "@mui/material";
import { usePatientDataContext } from "../../contexts/PatientDataContext";

import "./VerificationCode.css";
import numberInputStyles from "./VerificationCodeStyles";
import { Global } from "@emotion/react";

interface VerificationStepProps {
  promptInfo: PromptInfo;
  onLogin: (verificationCode: string) => void;
}

const VerificationStep: React.FC<VerificationStepProps> = ({
  promptInfo,
  onLogin,
}) => {
  const theme = useTheme();
  const errorColor = theme.palette.error.main;
  const [verificationCode, setVerificationCode] = useState("");
  const [, setVerificationCodeError] = useState("");
  const { setVerificationCodes } = usePatientDataContext();

  const { loading } = useAppContext();
  const validateVerificationCode = (value: string) => {
    if (!value) {
      setVerificationCodeError("Verification code is required");
      return false;
    } else if (!/^\d{6}$/.test(value)) {
      setVerificationCodeError("Verification code must be 6 digits");
      return false;
    } else {
      setVerificationCodeError("");
      return true;
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (validateVerificationCode(verificationCode)) {
      setVerificationCodes(verificationCode);
      onLogin(verificationCode);
    }
  };

  return (
    <Container>
      <Global styles={numberInputStyles} />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Verification step
              </Typography>
              <div style={{ textAlign: "center" }}>
                <p
                  style={{ color: errorColor }}
                  dangerouslySetInnerHTML={{ __html: promptInfo.redMessage }}
                ></p>
              </div>
              <form onSubmit={handleSubmit}>
                <MuiOtpInput
                  length={6}
                  value={verificationCode}
                  onChange={setVerificationCode}
                  TextFieldsProps={{
                    disabled: loading,
                    size: "small",
                    type: "number",
                  }}
                />

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ marginTop: "1rem" }}
                  disabled={loading}
                >
                  Submit {loading && <Loader />}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VerificationStep;
