import { useEffect, useState } from 'react'
import { Box, Modal, Typography, useTheme } from '@mui/material'
import { Link, useNavigate, useParams } from 'react-router-dom'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  height: '80%',
  maxWidth: '832px',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
}
export const Footer = () => {
  const [isTermAndConditionsOpen, setIsTermAndConditionsOpen] = useState(false)
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)

  const theme = useTheme()

  const { termPolicy } = useParams()
  const navigate = useNavigate()

  const closeModal = () => {
    navigate('/')
    setIsPrivacyPolicyOpen(false)
    setIsTermAndConditionsOpen(false)
  }

  useEffect(() => {
    if (!termPolicy) return
    setIsPrivacyPolicyOpen(termPolicy === 'privacy-policy')
    setIsTermAndConditionsOpen(termPolicy === 'terms-and-conditions')
  }, [termPolicy])

  return (
    <>
      <Box sx={{ alignItems: 'center', height: '40px', display: 'flex', justifyContent: 'center' }}>
        <Typography variant='body2'>
          <Link to='/terms-and-conditions' style={{ color: theme.palette.primary.main }}>
            Terms and Conditions
          </Link>
          {' | '}
          <Link to='/privacy-policy' style={{ color: theme.palette.primary.main }}>
            Privacy Policy
          </Link>
        </Typography>
      </Box>
      <Modal onClose={closeModal} open={isTermAndConditionsOpen || isPrivacyPolicyOpen}>
        <Box sx={style}>
          {isTermAndConditionsOpen && (
            <iframe
              width={'100%'}
              height={'100%'}
              src='https://docs.google.com/document/d/e/2PACX-1vQQDyHOMfTa06BAnpdpfDF6xxpXwKI0toSr81LC19TWgAiMlQnx6RdNv3Y8rAUFYgRsCtlAGBEQqP8c/pub?embedded=true'
            />
          )}
          {isPrivacyPolicyOpen && (
            <iframe
              width={'100%'}
              height={'100%'}
              src='https://docs.google.com/document/d/e/2PACX-1vTs1VABb23tHBA3VRjxkXjy8kVE0EeSM8VGcAP-8IdKTKuqMphDG0ZEZojqX836xMJFeWSh3kqjAxbD/pub?embedded=true'
            />
          )}
        </Box>
      </Modal>
    </>
  )
}
