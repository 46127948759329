import React, { useState, ChangeEvent, useRef } from 'react'
import { fetchData } from '../../api/apiService'
import { usePatientDataContext } from '../../contexts/PatientDataContext'
import { Step } from '../types'
import { useAppContext } from '../../contexts/AppContext'
import { useAppErrorContext } from '../../contexts/AppErrorContext'
import { UNEXPECTED_ERROR_MSG } from '../stringConstants'

import './ImageUpload.css'
import PassportControls from './PassportControls'
import { BasePassportPickerLayout } from './BasePassportPickerLayout'

interface ImageUploadProps {
  setStep: (step: Step) => void
}

export const ImageUpload: React.FC<ImageUploadProps> = ({ setStep }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { setLoading } = useAppContext()
  const { openErrorDialog } = useAppErrorContext()
  const [file, setFile] = useState<File | null>(null)
  const [validationMessage, setValidationMessage] = useState<string>('')
  const { sessionId, verificationCodes, patientId, contractId, facilityId } =
    usePatientDataContext()

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0]
    if (selectedFile && isImageFile(selectedFile)) {
      setFile(selectedFile)
      setValidationMessage('')
    } else {
      setValidationMessage('Please choose a valid image file (jpg, jpeg, png)')
    }
  }

  const handleConfirmFile = async () => {
    if (!file) {
      return
    }
    setLoading(true)

    const reader = new FileReader()
    let photoID = ''
    reader.onload = async function (e) {
      photoID = e.target?.result as string
      let responseData: any
      try {
        responseData = await fetchData('POST', '/npp/post-passport', {
          sessionId: sessionId,
          verificationCode: verificationCodes,
          pid: patientId,
          contractId: contractId,
          facilityId: facilityId,
          photoIDs: [photoID],
        })
      } catch (error) {
        openErrorDialog(UNEXPECTED_ERROR_MSG)
      } finally {
        setLoading(false)
      }
      if (!responseData) return
      if (responseData.data.errorMessage) {
        openErrorDialog(responseData.data.errorMessage)
      } else {
        setStep(responseData.data.nextStep)
      }
    }
    reader.readAsDataURL(file)
  }

  const isImageFile = (file: File) => {
    const allowedFormats = ['image/jpeg', 'image/jpg', 'image/png']
    return allowedFormats.includes(file.type)
  }

  const handleChooseAnotherFile = () => {
    fileInputRef.current?.click()
  }

  return (
    <>
      <BasePassportPickerLayout
        passportControls={
          <PassportControls
            handleChooseAnotherFile={handleChooseAnotherFile}
            handleConfirmFile={handleConfirmFile}
            uploadBtnLabel={'choose file'}
            submitFileDisabled={!file}
          />
        }
        passportMedia={
          file
            ? {
                mediaElement: (
                  <>
                    <img
                      src={URL.createObjectURL(file)}
                      alt='passport'
                      className={'passport__img'}
                    />
                  </>
                ),
                name: file?.name,
              }
            : undefined
        }
        hiddenFileInput={
          <>
            <input
              type='file'
              accept='image/*'
              style={{ display: 'none' }}
              id='fileInput'
              ref={fileInputRef}
              onChange={handleFileChange}
            />
          </>
        }
        validationMessage={validationMessage}
      ></BasePassportPickerLayout>
    </>
  )
}
