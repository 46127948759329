import React, { useRef, useState } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Box } from '@mui/material'

import { SignatureWidgetProps } from '../@types/SignatureWidget.types'
import { SignatureModal } from './SignatureModal'
import { getValidationRule } from '../../../utils/formHelper'
import { SignaturePlaceholder } from './SignaturePlaceholder'
import Tooltip from '@mui/material/Tooltip'
import { ErrorMessageForWidget } from '../ErrorMessageForWidget/ErrorMessageForWidget'
import { usePatientDataContext } from '../../../contexts/PatientDataContext'

export const SignatureWidget: React.FC<SignatureWidgetProps> = (field) => {
  const { role, label = `${role} signature`.toLocaleUpperCase() } = field
  const { control } = useFormContext()
  const { field: fieldSignature, fieldState } = useController({
    name: field.fieldId,
    control: control,
    rules: getValidationRule(field),
  })
  const { role: currentUserRole } = usePatientDataContext()
  const errorContainer = useRef<HTMLElement | null>(null)

  const [openSignatureModal, setOpenSignatureModal] = useState(false)

  const handleClickOpen = () => {
    setOpenSignatureModal(true)
  }

  const handleClose = () => {
    setOpenSignatureModal(false)
  }

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: 'fit-content',
        textAlign: 'start',
        margin: '0 auto',
      }}
    >
      {currentUserRole !== role ? (
        <Tooltip title={`This field is for ${role} users only`} arrow followCursor>
          <Box>
            <SignaturePlaceholder
              handleClickOpen={handleClickOpen}
              signatureProps={{ ...field, label: label }}
              openSignatureModal={false}
            />
          </Box>
        </Tooltip>
      ) : (
        <>
          <ErrorMessageForWidget
            isShowErrorMessage={true}
            fieldState={fieldState}
            displayLocationContainer={errorContainer}
          />
          <Box ref={errorContainer}></Box>
          <SignaturePlaceholder
            fieldState={fieldState}
            handleClickOpen={handleClickOpen}
            signatureProps={{ ...field, label: label }}
            openSignatureModal={openSignatureModal}
          />
        </>
      )}
      {currentUserRole === role && (
        <SignatureModal
          onChange={fieldSignature.onChange}
          openSignatureModal={openSignatureModal}
          setClose={handleClose}
          signatureProps={{
            ...field,
            label: label,
          }}
        />
      )}
    </Box>
  )
}

export default SignatureWidget
