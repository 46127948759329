import React, { useState, ChangeEvent, FormEvent, useRef } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Loader } from "../Loader";
import { useAppContext } from "../../contexts/AppContext";
import { formatPhoneNumber } from "../../utils/maskHelper";
import { ErrorMessageForWidget } from "../widgets/ErrorMessageForWidget/ErrorMessageForWidget";

interface AuthorizationProps {
  onLogin: (phoneNumber: string) => void;
}

const Authorization: React.FC<AuthorizationProps> = ({ onLogin }) => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const errorContainer = useRef<HTMLDivElement | null>(null);

  const { loading } = useAppContext();

  const validatePhoneNumber = (value: string) => {
    if (!value) {
      setPhoneNumberError("Phone number is required");
      return false;
    }
    const numericValue = value.replace(/[^0-9]/g, "");

    if (!/^\d{10}$/.test(numericValue)) {
      setPhoneNumberError("Phone number must be 10 digits");
      return false;
    }
    if (/^000\d{7}$/.test(numericValue)) {
      setPhoneNumberError("");
      return true;
    }

    const areaCode = numericValue.substring(0, 3);
    const exchangeCode = numericValue.substring(3, 6);

    if (areaCode[0] === "0" || exchangeCode[0] === "0") {
      setPhoneNumberError("Invalid area or exchange code");
      return false;
    }

    setPhoneNumberError("");
    return true;
  };

  const handlePhoneNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const fpn = formatPhoneNumber(value) || "";

    setPhoneNumber(fpn);
    validatePhoneNumber(value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validatePhoneNumber(phoneNumber)) {
      onLogin(phoneNumber);
    }
  };

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={8}>
          <Card>
            <CardContent>
              <Typography
                variant="h6"
                align="center"
                style={{ margin: "24px" }}
              >
                Authorization
              </Typography>
              <form onSubmit={handleSubmit}>
                <ErrorMessageForWidget
                  isShowErrorMessage={true}
                  fieldState={{
                    invalid: !phoneNumberError,
                    isDirty: true,
                    isTouched: true,
                    error: { message: phoneNumberError, type: "error" },
                  }}
                  displayLocationContainer={errorContainer}
                />
                <TextField
                  inputProps={{ maxLength: 12 }}
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  error={!!phoneNumberError}
                  InputLabelProps={{
                    shrink: !!phoneNumber,
                  }}
                  disabled={loading}
                />
                <div ref={errorContainer} style={{ height: "36px" }}></div>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                >
                  Next {loading && <Loader />}
                </Button>
              </form>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Authorization;
