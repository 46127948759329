import React, { FC } from 'react'

import { TextField } from '@mui/material'
import { InputProps as StandardInputProps } from '@mui/material/Input/Input'

import { InputWidgetProps } from '../@types/InputWidget.types'
import { getDocumentWidgetSX, getTextFieldInputProps } from '../../../utils/stylesHelper'

// import './InputWidget.css'
import { UIWidgetProps } from '../@types/UIWidget.types'
import { Global } from '@emotion/react'
import styles from './InputWidgetStyles'

export const InputWidget: FC<UIWidgetProps> = ({ props, cntrllrRenderProps }) => {
  const {
    type,
    label,
    fieldId,
    required,
    disabled,
    fullWidth = true,
    style,
    InputProps = {} as Partial<StandardInputProps>,
    InputLabelProps,
  } = props as InputWidgetProps

  const { onChange, fieldState, value } = cntrllrRenderProps ?? {}
  const inputProps = getTextFieldInputProps()
  const sx = getDocumentWidgetSX(style)

  return (
    <>
      <Global styles={styles.formLabel} />
      <Global styles={styles.inputWidget} />
      <Global styles={styles.formControl} />
      <div className='inputWidget' style={{ width: '100%' }}>
        <TextField
          id={fieldId}
          size={'small'}
          type={type}
          sx={sx}
          inputProps={inputProps}
          InputLabelProps={{
            shrink: !!value,
            ...InputLabelProps,
          }}
          InputProps={InputProps}
          value={value}
          error={!!fieldState?.error}
          label={label}
          placeholder={label}
          margin='normal'
          disabled={disabled}
          required={required}
          onChange={onChange}
          fullWidth={fullWidth}
          //   {...rest}
        />
      </div>
    </>
  )
}
