export function generateSessionId() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function getReferralParams(refProviderId?: number, attorneyId?: number) {
  let refParams = {};
  if (refProviderId) {
    refParams = { refProviderId: refProviderId };
  }
  if (attorneyId) {
    refParams = { ...refParams, attorneyId: attorneyId };
  }
  return refParams;
}

export function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
