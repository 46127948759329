import React, { createContext, useContext, useState, ReactNode } from 'react'

// Define the shape of the context value
interface QuestionnaireDraftContextValue {
  questionnaireDraft?: any
  setQuestionnaireDraft: React.Dispatch<React.SetStateAction<any>>
}

// Create a context for the mobile state
const QuestionnaireDraftContext = createContext<QuestionnaireDraftContextValue | undefined>(undefined)

export const useQuestionnaireDraftContext = (): QuestionnaireDraftContextValue => {
  const context = useContext(QuestionnaireDraftContext)
  if (!context) {
    throw new Error('useQuestionnaireDraftContext must be used within an QuestionnaireDraftProvider')
  }
  return context
}

interface QuestionnaireDraftProviderProps {
  children: ReactNode
}

export const QuestionnaireDraftProvider: React.FC<QuestionnaireDraftProviderProps> = ({ children }) => {
  const [questionnaireDraft, setQuestionnaireDraft] = useState<any>({})

  const contextValue: QuestionnaireDraftContextValue = {
    questionnaireDraft,
    setQuestionnaireDraft,
  }

  return (
    <QuestionnaireDraftContext.Provider value={contextValue}>{children}</QuestionnaireDraftContext.Provider>
  )
}
