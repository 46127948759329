// configLoader.ts
import developmentConfig from './config.development';
import productionConfig from './config.production';

const env = process.env.NODE_ENV || 'development';

const getConfig = () => {
    switch (env) {
        case 'production':
            return productionConfig;
        case 'development':
        default:
            return developmentConfig;
    }
};

export default getConfig();
