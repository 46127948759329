import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import WarningIcon from "@mui/icons-material/Warning";
import { PromptInfo, Step } from "./types";
import { Loader } from "./Loader";
import { useAppContext } from "../contexts/AppContext";
import { Box, useTheme } from "@mui/material";

interface PromptProps {
  promptInfo: PromptInfo;
  onSwitchStep: (step: Step) => void;
  // onLogin function basically
  onOkAction?: () => void;
}

const Prompt: React.FC<PromptProps> = ({
  promptInfo,
  onSwitchStep,
  onOkAction,
}) => {
  const theme = useTheme();
  const errorColor = theme.palette.error.main;

  const { loading } = useAppContext();

  const onOkClickHandler = () => {
    if (onOkAction) {
      onOkAction();
      return;
    }
    onSwitchStep(
      promptInfo.nextStep === Step.VERIFICATION_CODE
        ? Step.SEND_VERIFICATION_CODE
        : promptInfo.nextStep
    );
  };

  return (
    <Container>
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={8}>
          <Card>
            <CardContent>
              <Box style={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  variant="h6"
                  align="center"
                  style={{ position: "relative", display: "inline" }}
                >
                  <WarningIcon
                    sx={{ fontSize: 20 }}
                    style={{
                      top: "50%",
                      position: "absolute",
                      left: "-2rem",
                      transform: "translate(0, -50%)",
                    }}
                  />
                  {promptInfo.title}
                </Typography>
              </Box>
              <div style={{ textAlign: "center" }}>
                <p
                  style={{ color: errorColor }}
                  dangerouslySetInnerHTML={{ __html: promptInfo.errorMessage }}
                ></p>
                <p dangerouslySetInnerHTML={{ __html: promptInfo.message }}></p>
              </div>
              <Button
                type="button"
                variant="contained"
                color="primary"
                onClick={onOkClickHandler}
                fullWidth
                style={{ marginTop: "1rem" }}
                disabled={loading}
              >
                OK {loading && <Loader />}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Prompt;
