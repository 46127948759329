export interface PersonInfo {
  firstName?: string
  middleName?: string
  lastName?: string
  DOB?: string
}
export interface UserData extends PersonInfo {
  phoneNumber?: string
  pid?: number
  // Define other properties here as needed
}

export interface UserVerificationData {
  verificationCode?: string
  sendCode?: boolean
}

export interface UserWithVerificationData extends UserData, UserVerificationData {}

export interface PromptInfo {
  title: string
  message: string
  errorMessage: string
  redMessage: string
  nextStep: Step
  phoneNumberLast4?: string
  codeSent?: boolean
}

export enum Step {
  PHONE_AUTHORIZATION = 'phone_auth',
  PERSONAL_DATA = 'personal_data',
  SEND_VERIFICATION_CODE = 'send_verification_code',
  VERIFICATION_CODE = 'verification_code',
  PROMPT = 'prompt',
  PASSPORT_FORM = 'passport_form',
  PASSPORT_REMINDER= 'passport_reminder',
  NEW_PROFILE_FORM = 'new_profile_form',
  QUESTIONNAIRE_FORM = 'questionnaire_form',
  QUESTIONNAIRE_SUBMITTED_SUCCESSFULLY = 'questionnaire_submitted_successfully',
  QUESTIONNAIRE_SUBMITTED_ERROR = 'questionnaire_submitted_error',
  DOCUMENTS_FORM = 'documents_form',
  DOCUMENTS_SUBMITTED_SUCCESSFULLY = 'documents_submitted_successfully',
  DOCUMENTS_SUBMITTED_ERROR = 'documents_submitted_error',
}

export enum Status {
  success = 'success',
  error = 'error',
  info = 'info',
}

export type SubmissionInfo = {
  status: Status
  mainMessage: string
  details?: string
}

export type DocumentResponse = {
  documentHTML: string
  jsonData: any
  id: number
  title: string
}
