import React, { useEffect, useState } from 'react'
import { Button, Typography, Paper, Box } from '@mui/material'
import { useAppContext } from '../../contexts/AppContext'
import { ImageUpload } from './ImageUpload'
import { Step } from '../types'
import PassportCaptureComponent from './PassportCaptureComponent'

interface PassportPhotoPickerProps {
  setStep: (step: Step) => void
}

export const PassportPhotoPickerComponent: React.FC<PassportPhotoPickerProps> = ({ setStep }) => {
  const { isMobile, isKioskMode } = useAppContext()
  const isTouchDevice = matchMedia('(hover: none)').matches
  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches)

  const orientationHandler = (event: any) => {
    setIsPortrait(event.matches)
  }

  useEffect(() => {
    const orientationQuery = window.matchMedia('(orientation: portrait)')
    orientationQuery.addEventListener('change', orientationHandler)
    return () => {
      orientationQuery.removeEventListener('change', orientationHandler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const skipBtnHandler = async () => {
    setStep(isKioskMode ? Step.QUESTIONNAIRE_FORM : Step.PASSPORT_REMINDER)
  }

  return (
    <Paper
      elevation={3}
      style={{
        padding: isMobile ? '0' : '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: isPortrait ? '100px' : '200px',
        width: isPortrait ? (isMobile ? '95%' : '80%') : '100%',
        alignSelf: 'center',
      }}
    >
      <Box
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'row',
        }}
      >
        <Typography
          variant='h5'
          style={{
            left: '32px',
            position: 'relative',
            margin: '0 auto',
            textAlign: 'center',
            width: '40vw',
          }}
        >
          Provide your photo ID
        </Typography>
        <Button onClick={skipBtnHandler}>Skip</Button>
      </Box>
      <Box style={{ margin: '0 0 20px', width: '100%', height: '100%' }}>
        <Box>
          <Box
            style={{
              width: '100%',
              display: 'flex',
              boxSizing: 'border-box',
              flexDirection: 'column',
              height: '100%',
            }}
          >
            {isTouchDevice ? (
              <PassportCaptureComponent setStep={setStep} />
            ) : (
              <ImageUpload setStep={setStep} />
            )}
          </Box>
        </Box>
      </Box>
    </Paper>
  )
}
