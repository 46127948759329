import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { Loader } from "../Loader";
import { useAppContext } from "../../contexts/AppContext";
import { useAppErrorContext } from "../../contexts/AppErrorContext";
import { FormHelperText, Paper } from "@mui/material";
import { Step } from "../types";

import "lforms/dist/lforms/webcomponent/assets/lib/zone.min.js";
import "lforms/dist/lforms/webcomponent/runtime.js";
import "lforms/dist/lforms/webcomponent/polyfills.js";
import "lforms/dist/lforms/webcomponent/main.js";
import "lforms/dist/lforms/fhir/R4/lformsFHIR.min.js";
import "lforms/dist/lforms/webcomponent/styles.css";
import "./Form.css";
import styles from "./FormStyles";
import { Global } from "@emotion/react";
import { useQuestionnaireDraftContext } from "../../contexts/QuestionnaireDraftContext";
import { usePersonalLinkContext } from "../../contexts/PersonalLinkContext";
import { applyMasks } from "../../utils/maskHelper";
import { fetchData } from "../../api/apiService";
import { usePatientDataContext } from "../../contexts/PatientDataContext";

// Placeholder declaration for LForms
declare const LForms: any;

interface FormProps {
  step: Step.NEW_PROFILE_FORM | Step.QUESTIONNAIRE_FORM | Step.DOCUMENTS_FORM;
  onSubmit: (data: any) => void;
  onInitialization: (data: any) => void;
}

const Form: React.FC<FormProps> = ({ step, onSubmit, onInitialization }) => {
  const { loading, setLoading, isMobile } = useAppContext();
  // set local state for submitLoading
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const { setErrorMessage, openErrorDialog } = useAppErrorContext();
  const [formDef, setFormDef] = useState<any>(null);
  const [formSourceSubmit, setFormSourceSubmit] = useState<boolean>(false);
  const { questionnaireDraft } = useQuestionnaireDraftContext();
  const { refProviderId, attorneyId } = usePersonalLinkContext();
  const { refParams } = usePersonalLinkContext();
  const {
    sessionId,
    verificationCodes,
    patientId,
    phoneNumber,
    contractId,
    setContractId,
  } = usePatientDataContext();

  const formElement = useRef<HTMLDivElement | null>(null);
  const htmlElement = useRef<HTMLDivElement | null>(null);

  const setReadonlyFieldsForSpecificForm = () => {
    let readonlyFields: string[] = [];
    if (step === Step.NEW_PROFILE_FORM) {
      readonlyFields = [
        '[id="6300713197746/1/1"]',
        '[id="19023226162/1/1"]',
        '[id="2036554176854/1/1"]',
        // '[id="1219063476562/1/1"]',
        '[id="9780511808549/1/1"]',
        // '[aria-label="Date of Birth"]',
      ];
    }

    if (step === Step.QUESTIONNAIRE_FORM) {
      if (attorneyId) {
        readonlyFields = [
          '[id="4851389320111/1/1"]',
          '[id="2775401293975/1/1"]',
        ];
      }
      if (refProviderId) {
        readonlyFields.push('[id="5208127706158/1/1"]');
      }
    }

    setTimeout(function () {
      setReadOnly(readonlyFields);
    }, 1000);
  };

  useEffect(() => {
    const initLForm = async (draft: any) => {
      try {
        const formDefResponse = await fetch(
          `/${step}.json` + "?" + Math.random()
        );
        const formDefTmp = await formDefResponse.json();

        //formDef.item remove 2 last items
        if (step === Step.QUESTIONNAIRE_FORM) {
          formDefTmp.item.pop();
          formDefTmp.item.pop();
          setFormSourceSubmit(true);
        }

        setFormDef(formDefTmp);

        const options = {
          displayScoreWithAnswerText: false,
        };

        let formWithDraftData = null;

        let lformsQ = LForms.Util.convertFHIRQuestionnaireToLForms(
          formDefTmp,
          "R4"
        );
        formWithDraftData = lformsQ;

        if (draft) {
          formWithDraftData = LForms.Util.mergeFHIRDataIntoLForms(
            "QuestionnaireResponse",
            draft,
            lformsQ,
            "R4"
          );
        }

        LForms.Util.addFormToPage(formWithDraftData, "formContainer", options);

        setTimeout(() => {
          applyMasks(formElement);
        }, 1000);
        setTimeout(function () {
          const form = formElement.current;
          if (!form) {
            return;
          }
          form
            .querySelector('[id="4888257147126/1/1"]')
            ?.addEventListener("keyup", () => {
              applyMasks(formElement);
            });
        }, 1000);
        setReadonlyFieldsForSpecificForm();
      } catch (error) {
        console.error(error);
      }
    };

    const initializeForm = async () => {
      let loginData = {
        sessionId: sessionId,
        verificationCode: verificationCodes,
        pid: patientId,
        ...refParams,
        phoneNumber: phoneNumber,
      };
      let draft = questionnaireDraft;
      if (step === Step.QUESTIONNAIRE_FORM) {
        loginData = {
          ...loginData,
          patientWillBringPassport: true,
        };
      } 

      await onInitialization(loginData);

      await initLForm(draft);
    };
    initializeForm();
  }, [step]);

  const setReadOnly = (selectors: string[]) => {
    const form = formElement.current;

    if (!form) {
      return;
    }

    selectors.forEach((selector) => {
      const input = form.querySelector(`${selector}`);
      if (input) {
        input.setAttribute("readonly", "true");
        var elClone = input.cloneNode(true);
        if (input.parentNode && elClone) {
          input.parentNode.replaceChild(elClone, input);
        }
      }
    });
  };

  const submitForm = (event: any) => {
    event.preventDefault();

    let validation = LForms.Util.checkValidity(formElement.current);

    if (validation !== null && event.type !== "click") {
      setErrorMessage("Please fill out all required fields"); // Set error message
      openErrorDialog(validation[0]);
    } else {
      setLoading(true);
      setSubmitLoading(event.type === "submit");
      const submitFormWithLForms = () => {
        try {
          const options = {};
          const questionnaireResponse = LForms.Util.getFormFHIRData(
            "QuestionnaireResponse",
            "R4",
            formElement.current,
            options
          );

          if (formSourceSubmit) {
            let all_css = "";
            let styles = document.querySelectorAll("style");

            styles.forEach((style: any, index: number) => {
              all_css += style.innerHTML;
            });

            const htmlBlock = htmlElement.current;

            if (htmlBlock) {
              let formSource = htmlBlock.outerHTML;

              //remove all <!---->
              formSource = formSource.replace(/<!---->/g, "");
              formSource = formSource.replace(/ _ngcontent-rtl-c\d+=""/gm, "");
              formSource = formSource.replace(/ _nghost-rtl-c\d+=""/gm, "");
              formSource = formSource.replace(/ _ngcontent-bsn-c\d+=""/gm, "");

              //append css
              formSource = `<style>${all_css}</style>${formSource}`;

              let formSourceEncodedString = btoa(
                encodeURIComponent(formSource)
              );
              if (event.type === "click") {
                questionnaireResponse.status = "draft";
              }
              onSubmit({
                step,
                questionnaireResponse,
                formSource: formSourceEncodedString,
                contractId: contractId,
              });
            }
          } else {
            onSubmit({ step, questionnaireResponse, contractId: contractId });
            setContractId(undefined);
          }
        } catch (error) {
          console.error(error);
        }
      };

      if (formSourceSubmit) {
        buildHtml(submitFormWithLForms);
      } else {
        submitFormWithLForms();
      }
    }
  };

  const buildHtml = (submitFunc: any) => {
    const questionnaireResponse = LForms.Util.getFormFHIRData(
      "QuestionnaireResponse",
      "R4",
      formElement.current,
      {}
    );
    let formWithUserData = null;
    let lformsQ = LForms.Util.convertFHIRQuestionnaireToLForms(formDef, "R4");
    let options = {
      displayScoreWithAnswerText: false,
    };
    if (questionnaireResponse) {
      formWithUserData = LForms.Util.mergeFHIRDataIntoLForms(
        questionnaireResponse,
        lformsQ,
        "R4"
      );
      LForms.Util.addFormToPage(formWithUserData, htmlElement.current, options);
    } else {
      LForms.Util.addFormToPage(lformsQ, htmlElement.current, options);
    }

    setTimeout(() => {
      const htmlBlock = htmlElement.current;

      if (htmlBlock) {
        //get info from each input, and paste it in span before input

        const inputs = htmlBlock.querySelectorAll("input");

        inputs.forEach((input: any) => {
          const span = document.createElement("span");
          span.style.fontWeight = "bold";
          span.innerHTML = input.value;
          input.parentNode.insertBefore(span, input);
          input.style.display = "none";
        });

        const textareas = htmlBlock.querySelectorAll("textarea");
        textareas.forEach((textarea: any) => {
          const span = document.createElement("span");
          span.style.fontWeight = "bold";
          span.innerHTML = textarea.value;
          textarea.parentNode.insertBefore(span, textarea);
          textarea.style.display = "none";
        });

        const datepickers = htmlBlock.querySelectorAll(".lhc-date-picker");
        datepickers.forEach((datepicker: any) => {
          const span = document.createElement("span");
          span.style.fontWeight = "bold";
          span.innerHTML = datepicker.innerText;
          datepicker.parentNode.insertBefore(span, datepicker);
          datepicker.style.display = "none";
        });

        const autocomplete = htmlBlock.querySelectorAll(
          "lhc-item-choice-autocomplete"
        );
        // @ts-ignore
        const autocompleteButtons = htmlBlock.querySelectorAll(
          "lhc-item-choice-autocomplete button"
        );
        //remove autocomplete buttons
        autocompleteButtons.forEach((button: any) => {
          button.style.display = "none";
        });

        const autocompleteSelected =
          htmlBlock.querySelectorAll(".autocomp_selected");
        //remove class autocomplete-selected
        autocompleteSelected.forEach((selected: any) => {
          selected.classList.replace(
            "autocomp_selected",
            "autocomp_selected_replaced"
          );
          //add('autocomp_selected_replaced').remove('autocomp_selected');
        });

        const popover = htmlBlock.querySelectorAll("[nz-popover]");
        //remove popover
        popover.forEach((popover: any) => {
          popover.style.display = "none";
        });

        const lhcItemMessages =
          htmlBlock.querySelectorAll(".lhc-item-messages");
        lhcItemMessages.forEach((lhcItemMessage: any) => {
          lhcItemMessage.style.display = "none";
        });

        const lhcValidate = htmlBlock.querySelectorAll(".lhc-validate");
        lhcValidate.forEach((lhcValidate: any) => {
          lhcValidate.style.display = "none";
        });

        // Get all elements inside the specific block
        const elementsInsideBlock = htmlBlock.querySelectorAll("*");

        // Filter the invisible elements
        // @ts-ignore
        const invisibleElements = [...elementsInsideBlock].filter((element) => {
          const styles = getComputedStyle(element);
          return styles.display === "none" || styles.visibility === "hidden";
        });

        // Remove the invisible elements
        invisibleElements.forEach((element) => {
          element.parentNode?.removeChild(element);
        });

        submitFunc();
      }
    }, 1000);
  };

  return (
    <Paper
      elevation={3}
      style={{
        padding: isMobile ? "0" : "20px",
      }}
    >
      <div>
        <Global styles={styles.pickerInputHover} />
        <Global styles={styles.pickerReadonly} />
        <div ref={formElement} id="formContainer"></div>
        <form onSubmit={submitForm}>
          <Box
            style={{
              display: step === Step.QUESTIONNAIRE_FORM ? "flex" : "none",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "1rem 0" }}
              disabled={loading}
              onClick={submitForm}
            >
              Save draft and proceed later
              {loading && !submitLoading && <Loader />}
            </Button>
          </Box>
          <Box
            m={1}
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <FormHelperText>
              By submitting this form you consent to receive SMS messages for
              appointment reminders from California Back and Pain Specialists.
              These messages may include appointment details, scheduling
              information, and other relevant updates. Message and data rates
              may apply. You can unsubscribe at any time by contacting us or
              replying STOP to any message.
            </FormHelperText>

            <Button
              type="submit"
              variant="contained"
              color="success"
              style={{ height: "80px" }}
              fullWidth
              disabled={loading}
            >
              {step === Step.QUESTIONNAIRE_FORM
                ? "Finalize and Submit"
                : "Submit"}
              {loading && submitLoading && <Loader />}
            </Button>
          </Box>
        </form>
        <div
          style={{ position: "absolute", top: "-10000px", left: "-10000px" }}
        >
          <div ref={htmlElement} id="htmlContainer"></div>
        </div>
      </div>
    </Paper>
  );
};

export default Form;
