import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import { AppRouter } from './App'
import reportWebVitals from './reportWebVitals'
import { AppProvider } from './contexts/AppContext'
import { ErrorProvider } from './contexts/AppErrorContext'
import { PatientDataProvider } from './contexts/PatientDataContext'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <AppProvider>
      <ErrorProvider>
        <PatientDataProvider>
          <AppRouter />
        </PatientDataProvider>
      </ErrorProvider>
    </AppProvider>
  </React.StrictMode>
)

reportWebVitals()
