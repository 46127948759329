import React, { ReactNode, useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material'
import { useAppContext } from '../../contexts/AppContext'
import { GlobalLoader } from '../Loader'

import idCard from '../../assets/id-card1.png'
import { Global } from '@emotion/react'
import passportStyles from './ImageUploadStyles'

type Passport = {
  mediaElement: ReactNode
  name?: string
}

interface BasePassportPickerLayoutProps {
  validationMessage?: string
  passportControls: ReactNode
  passportMedia?: Passport
  hiddenFileInput?: ReactNode
  videoOutput?: { isVideoHidden: boolean; node: ReactNode }
}

export const BasePassportPickerLayout: React.FC<BasePassportPickerLayoutProps> = ({
  validationMessage,
  passportControls,
  passportMedia,
  hiddenFileInput,
  videoOutput,
}) => {
  const { loading, isMobile } = useAppContext()
  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches)

  const orientationHandler = (event: any) => {
    setIsPortrait(event.matches)
  }

  useEffect(() => {
    const orientationQuery = window.matchMedia('(orientation: portrait)')
    orientationQuery.addEventListener('change', orientationHandler)
    return () => {
      orientationQuery.removeEventListener('change', orientationHandler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth='sm' style={{ height: '100%', padding: '0' }} className='container'>
      <Global styles={passportStyles} />
      {hiddenFileInput}
      {loading && <GlobalLoader />}
      {!loading && (
        <div
          style={{
            height: '80%',
            width: '100%',
            minHeight: '24vmax',
          }}
        >
          <div className='passport__file-validation-message'>
            <Typography>{validationMessage}</Typography>
          </div>
          {!passportMedia && (!videoOutput || videoOutput?.isVideoHidden) && (
            <Box
              style={{
                margin: '0px auto',
                width: 'fit-content',
                display: 'flex',
                boxSizing: 'border-box',
                height: '100%',
              }}
            >
              <img
                src={idCard}
                alt='id-card'
                className={'passport__img'}
                style={{
                  opacity: '40%',
                }}
              />
            </Box>
          )}
          <div className='passport__img-container'>
            {passportMedia && (!videoOutput || videoOutput?.isVideoHidden) && (
              <>
                <Typography
                  variant='body1'
                  style={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: '36vw',
                    position: 'absolute',
                    top: '-20px',
                  }}
                >
                  {passportMedia.name && `Selected Image: ${passportMedia.name}`}
                </Typography>
                {passportMedia.mediaElement}
              </>
            )}
            {videoOutput && videoOutput.node}
          </div>
        </div>
      )}
      {!loading && (isPortrait || !isMobile) && <div style={{ height: '20%' }}>{passportControls}</div>}
      {!loading && !isPortrait && isMobile && passportControls}
    </Container>
  )
}
