import { FC, useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'

import { fetchData } from '../../api/apiService'
import { useAppErrorContext } from '../../contexts/AppErrorContext'
import { GlobalLoader } from '../Loader'
import { DocumentResponse, SubmissionInfo, Status } from '../types'
import { SubmissionInformation } from '../SubmissionInformation/SubmissionInformation'
import { UNEXPECTED_ERROR_MSG } from '../stringConstants'
import { WrapperForDocumentForm } from '../DocumentForm/WrapperForDocumentForm'
import { usePatientDataContext } from '../../contexts/PatientDataContext'
import { Button } from '@mui/material'
import { useDocumentFetchingInfrastructure } from '../../hooks/useDocumentFetchingInfrastructure'

export const LoopDocumentRetriever: FC = () => {
  const [documentResponse, setDocumentResponse] = useState<DocumentResponse | null>(null)
  const [globalLoading, setGlobalLoading] = useState(false)
  const { sessionId, verificationCodes, patientId, contractId, facilityId } =
    usePatientDataContext()
  const { setErrorMessage, openErrorDialog } = useAppErrorContext()
  const [showDocComponent, setShowDocComponent] = useState(false)
  const [showSubmissionInfo, setShowSubmissionInfo] = useState(false)
  const [showBtnRetryRequest, setShowBtnRetryRequest] = useState(false)
  const [submissionInfo, setSubmissionInfo] = useState<SubmissionInfo | null>(null)
  const [isLastDocument, setIsLastDocument] = useState(false)
  // const [showBtnRetryRequest, setShowBtnRetryRequest] = useState(false)
  const [getDocument] = useDocumentFetchingInfrastructure(
    setGlobalLoading,
    setDocumentResponse,
    setShowSubmissionInfo,
    setIsLastDocument,
    setSubmissionInfo,
    setShowBtnRetryRequest
  )

  useEffect(() => {
    getDocument()
    // return to the problem with callbacks and dependency arrays

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitDocument = (formData: FieldValues) => {
    if (!documentResponse) return
    setShowDocComponent(false)
    // wait time for animation to finish
    setTimeout(() => setGlobalLoading(true), 450)
    const postDocument = async (formData: FieldValues, documentId: number) => {
      try {
        const responseData = await fetchData('POST', `/npp/sign-document`, {
          documentId: documentId,
          pid: patientId,
          contractId: contractId,
          facilityId: facilityId,
          sessionId: sessionId,
          verificationCode: verificationCodes,
          formData: { ...formData },
        })
        if (responseData.data.errorMessage) {
          setSubmissionInfo({
            mainMessage: responseData.data.errorMessage,
            status: Status.error,
          })
        } else {
          //make request for next document
          getDocument()
          // some delay because document does not get updated immediately
          setTimeout(() => {
            setSubmissionInfo({
              mainMessage: responseData.data.successMessage,
              status: Status.success,
            })
          }, 100)
        }
        setShowSubmissionInfo(true)
      } catch (error) {
        setErrorMessage(UNEXPECTED_ERROR_MSG)
        openErrorDialog(UNEXPECTED_ERROR_MSG)
        // throw error;
      } finally {
        setGlobalLoading(false)
      }
    }
    window.scrollTo(0, 0)
    postDocument(formData, documentResponse.id)
  }

  return (
    <>
      {globalLoading && !showSubmissionInfo && <GlobalLoader />}

      {showSubmissionInfo && submissionInfo && (
        <SubmissionInformation
          info={submissionInfo}
          setShowSubmissionInfo={setShowSubmissionInfo}
          cbOnEndOfAnimation={isLastDocument ? () => (window.location.href = '/') : undefined}
          displayTime={isLastDocument ? 3000 : 1100}
        />
      )}
      {!globalLoading && !showSubmissionInfo && documentResponse && (
        <>
          <WrapperForDocumentForm
            documentResponse={documentResponse}
            onFormDataSubmit={handleSubmitDocument}
            setShowComponent={setShowDocComponent}
            showComponent={showDocComponent}
          />
        </>
      )}
      {!globalLoading && !showSubmissionInfo && showBtnRetryRequest && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={getDocument}
            variant='contained'
            color='success'
            style={{ padding: '20px', opacity: '90%' }}
          >
            Try to retrieve document again
          </Button>
        </div>
      )}
    </>
  )
}
