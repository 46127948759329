import { FC, ReactNode, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { getValidationRule } from '../../../utils/formHelper'
import { ErrorMessageForWidget } from '../ErrorMessageForWidget/ErrorMessageForWidget'
import { usePatientDataContext } from '../../../contexts/PatientDataContext'
import React from 'react'
import { FormWidgetProps } from '../@types/FormWidgetProps'

type FormControllerProps = {
  field: FormWidgetProps
  children: ReactNode
}

export const FormController: FC<FormControllerProps> = (props) => {
  const { field, children } = props
  const errorContainer = useRef<HTMLDivElement | null>(null)

  const { fieldId, role, isDocumentWidget, value } = field

  const currentRole = usePatientDataContext().role
  const isPartOfTheForm = (currentRole === role && isDocumentWidget) || !isDocumentWidget
  const propsNotPartOfTheForm = { ...field, required: false, disabled: true }
  const child = !isPartOfTheForm
    ? React.cloneElement(children as React.ReactElement, {
        props: { ...propsNotPartOfTheForm },
      })
    : children

  const { control } = useFormContext()

  return (
    <div style={{ marginBottom: isDocumentWidget ? '2px' : 0 }}>
      {isPartOfTheForm ? (
        <>
          <Controller
            render={({ field: { value, onChange, ...rest }, fieldState }) => {
              let widgetDisabled = field.disabled
              if (field.type === 'datepicker' || field.type === 'datetimepicker') {
                widgetDisabled = !!field.forSignature
              }
              const clonedChildren = React.cloneElement(children as React.ReactElement, {
                cntrllrRenderProps: { value, onChange: onChange, fieldState },
                props: { ...field, disabled: widgetDisabled },
              })
              return (
                <>
                  <ErrorMessageForWidget
                    isShowErrorMessage={true}
                    fieldState={fieldState}
                    displayLocationContainer={errorContainer}
                  />
                  {isDocumentWidget && <div ref={errorContainer}></div>}
                  {clonedChildren}
                  {!isDocumentWidget && <div ref={errorContainer} style={{ height: '36px' }}></div>}
                </>
              )
            }}
            control={control}
            defaultValue={value || ''}
            name={fieldId}
            rules={getValidationRule(field)}
          />
        </>
      ) : (
        <>{child}</>
      )}
    </div>
  )
}
