import { FC } from 'react'
import SignatureWidget from './SignatureWidget/SignatureWidget'
import { TextareaWidget } from './TextareaWidget/TextareaWidget'
import { FormController } from './FormController/FormController'
import { DatePickerWidget } from './DatePickerWidget/DatePickerWidget'
import { FormWidgetProps } from './@types/FormWidgetProps'
import { DateTimePickerWidget } from './DateTimePickerWidget.tsx/DateTimePickerWidget'
import { InputWidget } from './InputWidget/InputWidget'
import { CheckboxWidget } from './CheckboxWidget/CheckboxWidget'

export const GetFieldWidget: FC<FormWidgetProps> = (props) => {
  let child

  switch (props.type) {
    case 'datepicker':
      child = <DatePickerWidget props={props} />
      break
    case 'datetimepicker':
      child = <DateTimePickerWidget props={props} />
      break
    case 'text':
    case 'email':
    case 'password':
      child = <InputWidget props={props} />
      break
    case 'textarea':
      child = <TextareaWidget props={props} />
      break
    case 'checkbox':
      child = <CheckboxWidget props={props} />
      break
    case 'signature':
      return <SignatureWidget {...props} />
  }
  return <FormController field={props}>{child}</FormController>
}
