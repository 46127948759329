import { Modal, Box, Typography } from '@mui/material'
import { FC } from 'react'
import { SignatureFormWidget } from './SignatureFormWidget'
import { SignatureWidgetProps } from '../@types/SignatureWidget.types'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import './SignatureModal.css'

interface SignatureModalProps {
  setClose: () => void
  signatureProps: SignatureWidgetProps
  openSignatureModal: boolean
  onChange: (value: any) => void
}

export const SignatureModal: FC<SignatureModalProps> = (props) => {
  const { setClose, signatureProps, openSignatureModal: showSignatureModal, onChange } = props
  const { fieldId, label, required } = signatureProps

  return (
    <>
      <Modal open={showSignatureModal} onClose={setClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            padding: 4,
            borderRadius: '4px',
            outline: 'none',
          }}
        >
          <Typography variant='h6' color='black' style={{ fontStyle: 'normal' }}>
            {label}
            {required && '*'}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={setClose}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <SignatureFormWidget
            onSaveCB={setClose}
            id={fieldId}
            onChange={(value) => {
              onChange(value)
            }}
          />
        </Box>
      </Modal>
    </>
  )
}
