import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { DatePickerWidgetProps } from '../@types/DatePickerWidget.types'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { UIWidgetProps } from '../@types/UIWidget.types'

const DATE_FORMAT = 'MM/DD/YYYY'

export const DatePickerWidget: FC<UIWidgetProps> = ({ props, cntrllrRenderProps }) => {
  const {
    disabled,
    label,
    required,
    max_date,
    min_date,
    fieldId,
    style,
    forSignature,
    isDocumentWidget,
  } = props as DatePickerWidgetProps

  const { onChange, value, fieldState } = cntrllrRenderProps ?? {}

  const {
    watch,
  } = useFormContext()
  const [isShrink, setIsShrink] = useState(false)
  const [fourLastCharsInYear, setFourLastCharsInYear] = useState<string[]>([])
  const [isYearSelected, setIsYearSelected] = useState(false)
  const connectedSignature: any = watch(forSignature ?? '')

  const handleSignatureChange = useCallback(() => {
    const now = moment()
    onChange?.(now)
  }, [onChange])

  useEffect(() => {
    if (!connectedSignature) return
    handleSignatureChange()
    // quick fix
    setIsShrink(true)
  }, [connectedSignature, handleSignatureChange])

  const getValue = (value: any) => {
    const momentValue = moment(value)

    if (!value || !momentValue.isValid()) {
      return null
    }
    return momentValue
  }

  const sx = {
    margin: '0',
    input: {
      padding: '0',
      width: `${style?.width || '100px'}`,
      height: `${style?.height || '30px'}`,
      marginLeft: '10px',
      '&::placeholder': {
        fontSize: '14px',
        opacity: '0.7',
      },
    },
    button: { padding: '5px' },
    label: {
      '&[data-shrink=false]': {
        transform: 'translate(15px, -50%)',
        top: '50%',
      },
    },
  }

  return (
    <div
      style={{
        position: 'relative',
        width: `${style?.width || '100px'}`,
        height: `${style?.height || '30px'}`,
      }}
      onFocus={() => setIsShrink(true)}
      onBlur={() => setIsShrink(!!value)}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DesktopDatePicker
          onChange={(val) => {
            onChange?.(val)
          }}
          value={getValue(value)}
          maxDate={max_date}
          disableFuture={true}
          disabled={disabled}
          format= {DATE_FORMAT}
          views={['year', 'month', 'day']}
          label={label || 'Date'}
          minDate={min_date}
          onSelectedSectionsChange={(e) => setIsYearSelected(e === 2)}
          slotProps={{
            textField: {
              name: fieldId,
              error: !!fieldState?.error,
              size: 'small',
              margin: 'normal',
              required: required,
              fullWidth: !isDocumentWidget,
              sx: sx,
              InputLabelProps: { shrink: isShrink },
              onKeyDown: (e: React.KeyboardEvent<Element>) => {
                if (!isYearSelected) return
                if (e.key === 'Backspace') {
                  setFourLastCharsInYear([])
                  return
                }
                if (e.code.startsWith('Digit')) {
                  if (fourLastCharsInYear.length === 4) {
                    e.preventDefault()
                    return
                  }
                  setFourLastCharsInYear((prev) => [...prev, e.key])
                }
              },
            },
          }}
        />
      </LocalizationProvider>
    </div>
  )
}
