import { Box, Paper } from '@mui/material'
import React, { FC, useEffect, useRef, useState } from 'react'
import { SubmissionInfo, Status } from '../types'
import { CSSTransition } from 'react-transition-group'
import './SubmissionInformation.css'
import { InformationDisplayer } from '../InformationDisplayer/InformationDisplayer'
import itemAnimationStyles from './SubmissionInformationStyles'
import { Global } from '@emotion/react'

export type SubmissionInformationProps = {
  info: SubmissionInfo
  setShowSubmissionInfo: React.Dispatch<React.SetStateAction<boolean>>
  cbOnEndOfAnimation?: () => void
  displayTime: number
}

export const SubmissionInformation: FC<SubmissionInformationProps> = ({
  info,
  setShowSubmissionInfo,
  cbOnEndOfAnimation,
  displayTime,
}) => {
  const { status } = info
  const [inProp, setInProp] = useState(false)
  const nodeRef = useRef(null)

  useEffect(() => {
    setInProp(true)

    if (status === Status.error) return
    // 500 ms for appearing and disappearing
    let time = 1000 + displayTime

    setTimeout(() => {
      setInProp(false)
    }, time)

    setTimeout(() => {
      setShowSubmissionInfo(false)
      cbOnEndOfAnimation?.()
    }, time + 600)
  }, [])

  const onActionClick = () => {
    setInProp(false)

    setTimeout(() => {
      setShowSubmissionInfo(false)
    }, 600)
  }

  return (
    <>
      <Global styles={itemAnimationStyles} />
      <Box
        style={{
          margin: '0 auto',
        }}
      >
        <CSSTransition nodeRef={nodeRef} in={inProp} timeout={500} classNames='item' unmountOnExit>
          <Box ref={nodeRef}>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Paper
                elevation={2}
                style={{
                  padding: `0 ${status === Status.success ? '56' : '8'}px 0 8px`,
                }}
              >
                <InformationDisplayer
                  info={info}
                  action={
                    status === Status.error
                      ? { text: 'Close', clickHandler: onActionClick }
                      : undefined
                  }
                />
              </Paper>
            </Box>
          </Box>
        </CSSTransition>
      </Box>
    </>
  )
}
