import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import useElementSize from '../../../hooks/useElementSize'
import { useSignatureContext } from './SignatureFormContext'

interface SignatureFormWidgetProps {
  onSaveCB?: () => void
  id: string
  onChange: (value: any) => void
}

export const SignatureFormWidget: React.FC<SignatureFormWidgetProps> = ({
  onSaveCB,
  id,
  onChange,
}) => {
  const canvasRef = React.useRef<SignaturePad | null>(null)
  const [container, { width, height }] = useElementSize()
  const { updateSignature, getSignature } = useSignatureContext()
  const [disabled, setDisabled] = useState(true)
  const [wereChangesMade, setWereChangesMade] = useState(false)

  useEffect(() => {
    const signature = getSignature(id)

    if (!wereChangesMade && signature) {
      canvasRef.current?.fromData(signature)
    }
  })

  const clear = () => {
    if (canvasRef.current) {
      canvasRef.current.clear()
      setDisabled(true)
      setWereChangesMade(true)
    }
  }

  const handleEnd = () => {
    setDisabled(false)
    setWereChangesMade(true)
  }

  const save = () => {
    if (canvasRef.current && !canvasRef.current.isEmpty()) {
      // it does not support it, it supports only png
      const imageUrl = canvasRef.current.getTrimmedCanvas()?.toDataURL('image/svg+xml', 1)
      if (imageUrl) {
        const points = canvasRef.current.toData()
        updateSignature(id, points)
        onChange(imageUrl)
        onSaveCB?.()
      }
    }
  }

  return (
    <>
      <div
        ref={container}
        style={{
          position: 'relative',
          width: '400px',
          height: '200px',
          border: '1px solid black',
          margin: '40px 0',
          boxSizing: 'border-box',
        }}
      >
        <Button
          type='button'
          onClick={clear}
          style={{ position: 'absolute', top: '-40px', right: '0px' }}
        >
          Clear
        </Button>
        <SignaturePad
          ref={canvasRef}
          onEnd={handleEnd}
          canvasProps={{
            width: width,
            height: height,
          }}
        />
      </div>
            <Button variant='contained' fullWidth onClick={save} disabled={disabled}>
        Save
      </Button>
    </>
  )
}
