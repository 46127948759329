import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { getReferralParams } from '../utils/miscellaneous'

// Define the shape of the context value
interface PersonalLinkContextValue {
  refProviderId?: number
  setRefProviderId: React.Dispatch<React.SetStateAction<number | undefined>>
  attorneyId?: number
  setAttorneyId: React.Dispatch<React.SetStateAction<number | undefined>>
  refParams?: any
}

// Create a context for the mobile state
const PersonalLinkContext = createContext<PersonalLinkContextValue | undefined>(undefined)

export const usePersonalLinkContext = (): PersonalLinkContextValue => {
  const context = useContext(PersonalLinkContext)
  if (!context) {
    throw new Error('usePersonalLinkContext must be used within an PersonalLinkProvider')
  }
  return context
}

interface PersonalLinkProviderProps {
  children: ReactNode
}

export const PersonalLinkProvider: React.FC<PersonalLinkProviderProps> = ({ children }) => {
  const [refProviderId, setRefProviderId] = useState<number | undefined>()
  const [attorneyId, setAttorneyId] = useState<number | undefined>()
  const [refParams, setRefParams] = useState<any>({})

  useEffect(() => {
    const paramsObj = getReferralParams(refProviderId, attorneyId)
    setRefParams(paramsObj)
  }, [refProviderId, attorneyId])

  const contextValue: PersonalLinkContextValue = {
    refProviderId,
    setRefProviderId,
    attorneyId,
    setAttorneyId,
    refParams,
  }

  return (
    <PersonalLinkContext.Provider value={contextValue}>{children}</PersonalLinkContext.Provider>
  )
}
