import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react'
import { useMediaQuery } from '@mui/material'

// Define the shape of the context value
interface AppContextValue {
  isMobile: boolean
  setIsMobile: React.Dispatch<React.SetStateAction<boolean>>
  isKioskMode: boolean
  setIsKioskMode: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

// Create a context for the mobile state
const AppContext = createContext<AppContextValue | undefined>(undefined)

export const useAppContext = (): AppContextValue => {
  const context = useContext(AppContext)
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider')
  }
  return context
}

interface AppProviderProps {
  children: ReactNode
}

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [isMobile, setIsMobile] = useState(false)
  const [isKioskMode, setIsKioskMode] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)

  // Use the useMediaQuery hook directly in the component
  const isMobileDevice = useMediaQuery('(max-width: 600px) or (max-height: 600px)')

  useEffect(() => {
    setIsMobile(isMobileDevice)
  }, [isMobileDevice])

  const contextValue: AppContextValue = {
    isMobile,
    setIsMobile,
    isKioskMode,
    setIsKioskMode,
    loading,
    setLoading,
  }

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}
