import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Paper,
  useTheme,
} from '@mui/material'
import React, { FC, useState } from 'react'
import { Step, Status } from '../types'
import { InformationDisplayer } from '../InformationDisplayer/InformationDisplayer'
import { usePersonalLinkContext } from '../../contexts/PersonalLinkContext'
import { usePatientDataContext } from '../../contexts/PatientDataContext'
import { useQuestionnaireDraftContext } from '../../contexts/QuestionnaireDraftContext'

interface PassportReminderProps {
  // Add any necessary prop types here
  // onAction: (data: PersonInfo) => void
  setStep: (step: Step) => void
}

export const PassportReminder: FC<PassportReminderProps> = ({ setStep }) => {
  const [checked, setChecked] = useState(false)
  const [isDirty, setDirty] = useState(false)
  const theme = useTheme()

  const colorHelperText = !checked && isDirty ? theme.palette.error.main : 'transparent'
  return (
    <Box
      style={{
        margin: '0 auto',
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Paper
          elevation={2}
          style={{
            width: '90%',
            padding: `0 8px 0 8px`,
          }}
        >
          <InformationDisplayer
            info={{
              status: Status.info,
              mainMessage: `Please have your photo ID, insurance card, any referral documents and any imaging performed after your accident ready for your appointment.
                We kindly ask you to complete a questionnaire regarding your injury before your visit.
                Please check the box below to confirm that you will bring any form of identification with you.
                `,
            }}
            action={{
              text: 'Next',
              clickHandler: async () => {
                setStep(Step.QUESTIONNAIRE_FORM)
              },
              disabled: !checked,
            }}
            title={'Important information for your visit'}
            btnVariant='contained'
            children={
              <FormControl
                component='fieldset'
                style={{ margin: '28px 0 4px' }}
                required
                error={!checked}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={() => {
                        setDirty(true)
                        setChecked(!checked)
                      }}
                      name='confirmation'
                    />
                  }
                  label='I confirm that I will bring my identification.'
                />

                <FormHelperText style={{ margin: '0', color: colorHelperText }}>
                  You must acknowledge that you have read the message, and that you will bring your
                  ID
                </FormHelperText>
              </FormControl>
            }
          />
        </Paper>
      </Box>
    </Box>
  )
}
