import { CSSProperties } from 'react'

function getDimensionStyle(dimension: any): string {
  const style: string = dimension ? `${dimension}` : 'inherit'
  return style
}
export function getDocumentWidgetSX(style: Record<string, any> | undefined) {
  const widgetStyle: CSSProperties = {
    height: getDimensionStyle(style?.height || '30px'),
    width: getDimensionStyle(style?.width || '154px'),
    fontSize: '14px',
    boxSizing: `border-box`,
  }
  const sx = {
    margin: 0,
    '& .MuiOutlinedInput-root, textarea, input': {
      ...widgetStyle,
      '&::placeholder': {
        opacity: 0.7,
      },
    },
  }
  return sx
}

export function getTextFieldInputProps() {
  const widgetStyle: CSSProperties = {
    height: '100%',
    paddingBottom: 0,
    paddingTop: 0,
  }
  const inputProps = {
    style: widgetStyle,
  }
  return inputProps
}

export function getDimensionInUnits(units: string, fallbackValue: number, dimension?: string) {
  if (dimension) return dimension
  return `${fallbackValue}${units}`
}

export function getMaxRowsForTextArea(element: HTMLElement): number {
      const elStyle = window.getComputedStyle(element, null);
      let elementHeight = element.clientHeight;
      elementHeight -= parseFloat(elStyle.paddingTop) + parseFloat(elStyle.paddingBottom);
      const lineHeight = parseFloat(elStyle.lineHeight);

      const maxRows = Math.floor(elementHeight / lineHeight);
      return maxRows;
}
