import { Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useAppContext } from '../../contexts/AppContext'

interface PassportControlsProps {
  handleChooseAnotherFile: () => void
  handleConfirmFile: () => void
  uploadBtnLabel: string
  submitFileDisabled: boolean
}

const PassportControls: React.FC<PassportControlsProps> = ({
  handleChooseAnotherFile,
  handleConfirmFile,
  uploadBtnLabel,
  submitFileDisabled = true
}) => {
  const { isMobile } = useAppContext()
  const [isPortrait, setIsPortrait] = useState(window.matchMedia('(orientation: portrait)').matches)

  const orientationHandler = (event: any) => {
    setIsPortrait(event.matches)
  }

  useEffect(() => {
    const orientationQuery = window.matchMedia('(orientation: portrait)')
    orientationQuery.addEventListener('change', orientationHandler)
    return () => {
      orientationQuery.removeEventListener('change', orientationHandler)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className='passport__buttons-container'>
      <div className='passport__button-group'>
        <Button
          variant='outlined'
          onClick={handleChooseAnotherFile}
          className={'passport__button'}
          style={{ width: isPortrait || !isMobile ? '46%' : '98%' }}
        >
          {uploadBtnLabel}
        </Button>
        <Button
          variant='contained'
          className={'passport__button'}
          disabled={submitFileDisabled}
          onClick={handleConfirmFile}
          style={{ width: isPortrait || !isMobile ? '46%' : '98%' }}
        >
          Submit File
        </Button>
      </div>
    </div>
  )
}

export default PassportControls
