import { Box, Portal, useTheme } from '@mui/material'
import React, { RefObject } from 'react'
import { FieldError } from 'react-hook-form'

interface ErrorMessageForWidgetProps {
  isShowErrorMessage: boolean
  fieldState: {
    invalid: boolean
    isDirty: boolean
    isTouched: boolean
    error?: FieldError | undefined
  }
  displayLocationContainer: RefObject<HTMLElement | null>
}

export const ErrorMessageForWidget: React.FC<ErrorMessageForWidgetProps> = ({
  isShowErrorMessage,
  fieldState,
  displayLocationContainer,
}) => {
  const theme = useTheme()

  const errorColor = theme.palette.error.main
  // do refactor
  return (
    <>
      <Portal container={() => displayLocationContainer.current!}>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignSelf: 'flex-start',
            alignItems: 'start',
            minHeight: '28px'
          }}
        >
          {isShowErrorMessage &&
            (fieldState?.error && fieldState.error?.message ? (
              <p style={{ color: errorColor, fontSize: '13px', margin: '4px 0' }}>
                {fieldState.error?.message}
              </p>
            ) : null)}
        </Box>
      </Portal>
    </>
  )
}
