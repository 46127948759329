import { CircularProgress, Box } from '@mui/material'
import React from 'react'

export const Loader = () => {
  return <CircularProgress size='1.5em' sx={{ marginLeft: '20px' }} />
}

export const GlobalLoader = () => {
  return (
    <Box
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '400px',
        height: '100%',
      }}
    >
      <CircularProgress size='4em' />
    </Box>
  )
}
