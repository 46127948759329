import React, { createContext, ReactNode, useContext, useState } from 'react'

interface SignatureFormContextValue {
  getSignature: (key: string) => SignaturePad.Point[][]
  addSignature: (key: string) => void
  updateSignature: (key: string, points: SignaturePad.Point[][]) => void
  removeSignature: (key: string) => void
}

interface SignatureProviderProps {
  children: ReactNode
}

const SignatureFormContext = createContext<SignatureFormContextValue | undefined>(undefined)

export const SignatureFormProvider: React.FC<SignatureProviderProps> = ({ children }) => {
  const [signatures, setSignatures] = useState<Record<string, SignaturePad.Point[][]>>({})

  const getSignature = (key: string) => {
    return signatures[key];
  }

  const addSignature = (key: string) => {
    setSignatures((prevSignatures) => ({
      ...prevSignatures,
      [key]: [],
    }))
  }

  const updateSignature = (key: string, points: SignaturePad.Point[][]) => {
    setSignatures((prevSignatures) => ({
      ...prevSignatures,
      [key]: points,
    }))
  }

  const removeSignature = (key: string) => {
    setSignatures((prevSignatures) => {
      const newSignatures: any = { ...prevSignatures }
      delete newSignatures[key]
      return newSignatures
    })
  }

  return (
    <SignatureFormContext.Provider
      value={{ getSignature: getSignature, addSignature, updateSignature, removeSignature }}
    >
      {children}
    </SignatureFormContext.Provider>
  )
}

export const useSignatureContext = (): SignatureFormContextValue => {
  const context = useContext(SignatureFormContext)
  if (!context) {
    throw new Error('useSignature must be used within a SignatureProvider')
  }
  return context
}
