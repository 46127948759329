import moment from 'moment'
import { FormWidgetProps } from '../components/widgets/@types/FormWidgetProps'

export const getValidationRule = (field: FormWidgetProps) => {
  const maxLengthAttr =
    field.type === 'signature' || field.type === 'textarea'
      ? {}
      : {
          maxLength: {
            value: field.maxLength || 50,
            message: `Max ${field.maxLength || 50} characters`,
          },
        }

  let defaultRule = {
    required: field.required && (field.validationMessage || 'The field is required'),
    ...maxLengthAttr,
  }

  if (field.type === 'datepicker') {
    const { maxAgePolicy, minAgePolicy } = field
    // @ts-ignore
    defaultRule = {
      // @ts-ignore
      validate: (value: string) =>
        validateDatePicker(value, field.required, minAgePolicy, maxAgePolicy, field.isDocumentWidget),
      ...defaultRule,
    }
  }

  return defaultRule
}

const validateDatePicker = (
  value: string,
  isRequired = false,
  minAgePolicy = 18,
  maxAgePolicy = 100,
  isDocumentWidget = false
): string | true => {
  const dateFormat = 'YYYY-MM-DDTHH:mm:ssZ'

  if (!isRequired && !(value || value === undefined)) {
    return true
  }

  const momentDate = moment(value, dateFormat, true)
  if (!momentDate.isValid()) {
    return `Please, enter a valid date`
  }

  return true
}