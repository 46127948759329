import { createTheme, Theme, ThemeOptions } from '@mui/material/styles'
import { Role } from './models/Role'

export interface Colors {
  bgColor: string
  borderColor: string
}

export function determineColorProperty(role: Role, isError: boolean, theme: Theme, property: 'borderColor' | 'bgColor') {
  if (isError) return theme.palette?.error.main;

  const colors = determineColors(role, theme);
  return colors ? colors[property] : '';
}

function determineColors(role: Role, theme: Theme): Colors | undefined {
  const palette = theme.palette as CBPPaletteOptions
  return palette[role]
}

export interface CBPPaletteOptions extends ThemeOptions {
  patient?: Colors
  provider?: Colors
  attorney?: Colors
}

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1469a6',
    },
    secondary: {
      main: '#a3a614',
    },
    patient: {
      bgColor: '#e3e4b9',
      borderColor: '#a3a614',
    },
    provider: {
      bgColor: '#eee3f4',
      borderColor: '#6e14a6',
    },
    attorney: {
      bgColor: '#c8c8c8',
      borderColor: '#474648',
    },
    success: {
      main: '#5f9512',
      light: '#e1edd0',
    },
    error: {
      light: '#f5cece',
      main: 'rgb(211, 47, 47)',
    },
  },
} as CBPPaletteOptions)
