import React, { useEffect, useState } from "react";
import { AppBar, Box, Container, CssBaseline, Toolbar } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { useAppContext } from "./contexts/AppContext";
import { useAppErrorContext } from "./contexts/AppErrorContext";
import {
  PersonInfo,
  PromptInfo,
  Step,
  UserWithVerificationData,
} from "./components/types";

import "./assets/base.css";
import "./assets/main.css";
import { Footer } from "./components/Footer";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { fetchData, loginUser } from "./api/apiService";
import { GetStepComponent } from "./components/GetStepComponent/GetStepComponent";
import { theme } from "./Theme";
import { ModalError } from "./components/ModalError/ModalError";
import { UNEXPECTED_ERROR_MSG } from "./components/stringConstants";
import { usePatientDataContext } from "./contexts/PatientDataContext";
import { UnauthorizedComponent } from "./components/UnathorizedComponent/UnauthorizedComponent";
import {
  PersonalLinkProvider,
  usePersonalLinkContext,
} from "./contexts/PersonalLinkContext";
import {
  QuestionnaireDraftProvider,
  useQuestionnaireDraftContext,
} from "./contexts/QuestionnaireDraftContext";
import { generateSessionId, sleep } from "./utils/miscellaneous";

export const App = () => {
  const { setRefProviderId, setAttorneyId } = usePersonalLinkContext();
  const { aId, pId } = useParams();
  const { refParams } = usePersonalLinkContext();
  const [authorized, setAuthorized] = useState<boolean>(false);
  const [step, setStep] = useState<Step>(Step.PHONE_AUTHORIZATION);
  const [dataset, setDataset] = useState<UserWithVerificationData>({});
  const { setQuestionnaireDraft } = useQuestionnaireDraftContext();
  const [promptInfo, setPromptInfo] = useState<PromptInfo>({
    title: "",
    message: "",
    errorMessage: "",
    redMessage: "",
    nextStep: Step.PHONE_AUTHORIZATION,
  });

  const { setErrorMessage, openErrorDialog } = useAppErrorContext();

  const phoneCountryCode =
    new URLSearchParams(window.location.search).get("pc") || "";

  const { isMobile, setLoading, setIsKioskMode } = useAppContext();

  const {
    setFacilityId,
    setSessionId,
    setPatientId,
    setContractId,
    setPhoneNumber,
  } = usePatientDataContext();

  const [isPortrait, setIsPortrait] = useState(
    window.matchMedia("(orientation: portrait)").matches
  );

  const orientationHandler = (event: any) => {
    setIsPortrait(event.matches);
  };

  useEffect(() => {
    const orientationQuery = window.matchMedia("(orientation: portrait)");
    orientationQuery.addEventListener("change", orientationHandler);

    setRefProviderId(parseInt(pId ?? ""));
    setAttorneyId(parseInt(aId ?? ""));

    return () => {
      orientationQuery.removeEventListener("change", orientationHandler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function loginWithPhone(phoneNumber: string) {
    setLoading(true);

    setPhoneNumber(phoneNumber);

    let sessionId = generateSessionId();
    setSessionId(sessionId);

    const postData = {
      phoneNumber: phoneNumber,
      sessionId: sessionId,
      phoneCountryCode: phoneCountryCode,
      ...refParams,
    };
    setDataset(postData);
    try {
      const responseData = await loginUser(postData);
      setLoading(false);

      if (responseData.data.errorMessage) {
        setErrorMessage("Error");
        openErrorDialog(responseData.data.errorMessage);
      } else {
        setStep(
          responseData.data.step === Step.VERIFICATION_CODE
            ? Step.SEND_VERIFICATION_CODE
            : responseData.data.step
        );
        setPromptInfo(responseData.data);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(UNEXPECTED_ERROR_MSG);
      openErrorDialog(UNEXPECTED_ERROR_MSG);
    }
  }

  async function loginWithPersonalInfo(personalInfo: PersonInfo) {
    setLoading(true);

    const postData = {
      ...dataset,
      ...personalInfo,
    };
    setDataset(postData);
    try {
      const responseData = await loginUser(postData);
      setLoading(false);

      if (responseData.data.errorMessage) {
        setErrorMessage("Error");
        openErrorDialog(responseData.data.errorMessage);
      } else {
        setStep(
          responseData.data.step === Step.VERIFICATION_CODE
            ? Step.SEND_VERIFICATION_CODE
            : responseData.data.step
        );
        setPromptInfo(responseData.data);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(UNEXPECTED_ERROR_MSG);
      openErrorDialog(UNEXPECTED_ERROR_MSG);
    }
  }

  async function loginWithSendCode(sendCode: boolean) {
    setLoading(true);
    const postData = { ...dataset, sendCode };
    setDataset(postData);

    try {
      const responseData = await loginUser(postData);
      setLoading(false);

      if (responseData.data.errorMessage) {
        setErrorMessage("Error");
        openErrorDialog(responseData.data.errorMessage);
      } else {
        setStep(responseData.data.step);
        setPromptInfo(responseData.data);
        setIsKioskMode(responseData.data.isKioskMode);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(UNEXPECTED_ERROR_MSG);
      openErrorDialog(UNEXPECTED_ERROR_MSG);
    }
  }

  async function loginWithVerificationCode(verificationCode: string) {
    setLoading(true);

    const postData = {
      ...dataset,
      verificationCode: verificationCode,
    };

    setDataset(postData);

    try {
      const responseData = await loginUser(postData);
      setLoading(false);

      if (responseData.data.errorMessage) {
        setErrorMessage("Error");
        openErrorDialog(responseData.data.errorMessage);
      } else {
        setStep(responseData.data.step);
        setAuthorized(responseData.data.authorized);
        setPatientId(responseData.data.pid);
        setPromptInfo(responseData.data);
        if (responseData.data.questionnaireDraft) {
          setQuestionnaireDraft(
            JSON.parse(responseData.data.questionnaireDraft)
          );
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(UNEXPECTED_ERROR_MSG);
      openErrorDialog(UNEXPECTED_ERROR_MSG);
    }
  }

  async function login(data: UserWithVerificationData) {
    let postData: any = {
      ...data,
      ...dataset,
    };

    setDataset(postData);

    setLoading(true);

    try {
      const responseData = await loginUser(postData);
      setLoading(false);

      if (responseData.data.errorMessage) {
        setErrorMessage("Error");
        openErrorDialog(responseData.data.errorMessage);
      } else {
        setStep(responseData.data.step);
        setPromptInfo(responseData.data);
        setDataset((prevData) => ({ ...prevData, pid: responseData.data.pid }));
        setPatientId(responseData.data.pid);
        setFacilityId(responseData.data.facilityId);
        setAuthorized(responseData.data.authorized);
        if (responseData.data.contractId) {
          setContractId(responseData.data.contractId);
        }
        if (responseData.data.questionnaireDraft) {
          setQuestionnaireDraft(
            JSON.parse(responseData.data.questionnaireDraft)
          );
        }
        if (responseData.data.contractAttorneyId) {
          setAttorneyId(responseData.data.contractAttorneyId);
        }
        if (responseData.data.isKioskMode) {
          setIsKioskMode(responseData.data.isKioskMode);
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(UNEXPECTED_ERROR_MSG);
      openErrorDialog(UNEXPECTED_ERROR_MSG);
    }
  }

  async function submitForm({
    step,
    questionnaireResponse,
    formSource,
    contractId,
  }: {
    step: Step;
    questionnaireResponse: Record<string, any>;
    formSource: string;
    contractId: string;
  }) {
    const data = {
      ...dataset,
      questionnaireResponse,
      formSource,
      ...refParams,
      contractId: contractId,
    };

    let url = "";
    switch (step) {
      case Step.NEW_PROFILE_FORM:
        url = "/npp/create-patient";
        break;
      case Step.QUESTIONNAIRE_FORM:
        url = "/npp/questionnaire-response";
        break;
    }

    try {
      setLoading(true);
      const responseData = await fetchData("POST", url, data);

      setLoading(false);

      if (responseData.data.errorMessage) {
        setErrorMessage("Error");
        openErrorDialog(responseData.data.errorMessage);
      } else {
        if (responseData.data.pid) {
          setPatientId(responseData.data.pid);
        }

        setStep(responseData.data.step);
        setPromptInfo(responseData.data);
        setAuthorized(responseData.data.authorized);
        //scroll To Top
        window.scrollTo(0, 0);
      }

      if (step === Step.NEW_PROFILE_FORM && responseData.data.pid) {
        setDataset((prevData) => ({ ...prevData, pid: responseData.data.pid }));
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(UNEXPECTED_ERROR_MSG);
      openErrorDialog(UNEXPECTED_ERROR_MSG);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div
        id="app_wrapper"
        className={authorized ? "layout_authorized1" : "layout_not_authorized1"}
      >
        <Box
          style={
            !isPortrait && isMobile
              ? {
                  display: "flex",
                  flexDirection: "row",
                }
              : undefined
          }
        >
          <AppBar
            position="static"
            color="transparent"
            elevation={0}
            style={
              !isPortrait && isMobile ? { width: "fit-content" } : undefined
            }
          >
            <Toolbar
              style={
                !isPortrait && isMobile
                  ? {
                      padding: "0",
                      width: "fit-content",
                    }
                  : undefined
              }
            >
              <a href="/">
                <img
                  alt="CBP"
                  className="logo"
                  src="/cbp_logo.png"
                  style={{
                    width: "85px",
                    height: "75px",
                    margin: !isPortrait && isMobile ? "0" : "20px 0",
                  }}
                />
              </a>
            </Toolbar>
          </AppBar>
          <Container
            maxWidth="md"
            style={{
              paddingLeft: isMobile ? "6px" : "16px",
              paddingRight: isMobile ? "6px" : "16px",
              minHeight: "90vh",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              boxSizing: "border-box",
            }}
          >
            <main
              style={{
                minHeight: "calc(100% - 40px)",
                flex: "1",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {authorized ? (
                <GetStepComponent
                  step={step}
                  props={{ submitForm, isMobile, setStep, promptInfo, login }}
                />
              ) : (
                <UnauthorizedComponent
                  step={step}
                  props={{
                    login,
                    setStep,
                    promptInfo,
                    loginWithPhone,
                    loginWithPersonalInfo,
                    loginWithSendCode,
                    loginWithVerificationCode,
                  }}
                />
              )}
            </main>
            <ModalError />
            <Footer />
          </Container>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export const AppRouter = () => {
  return (
    <BrowserRouter>
      <PersonalLinkProvider>
        <QuestionnaireDraftProvider>
          <Routes>
            <Route path="/a/:aId/p/:pId" element={<App />} />
            <Route path="/a/:aId" element={<App />} />
            <Route path="/p/:pId" element={<App />} />
            <Route index path="/:termPolicy?" element={<App />} />
          </Routes>
        </QuestionnaireDraftProvider>
      </PersonalLinkProvider>
    </BrowserRouter>
  );
};
