import { FC } from 'react'
import { UIWidgetProps } from '../@types/UIWidget.types'
import { CheckboxWidgetProps } from '../@types/CheckboxWidget.type'
import { Checkbox } from '@mui/material'

export const CheckboxWidget: FC<UIWidgetProps> = ({ props, cntrllrRenderProps }) => {
  const { fieldId, required, disabled } = props as CheckboxWidgetProps

  const { onChange, value, fieldState } = cntrllrRenderProps ?? {}

  const overridedStyles = {
    '&.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-sizeMedium.MuiCheckbox-root.MuiCheckbox-sizeMedium':
      {
        padding: '0'
      },
  }
  return (
    <Checkbox
      id={fieldId}
      value={value}
      required={required}
      disabled={disabled}
      onChange={onChange}
      sx={{
        ...overridedStyles,
        color: `${!!fieldState?.error ? 'red' : 'black'}`,
      }}
    />
  )
}
