import { css } from '@emotion/react'

const documentStyles = css`
  .document-enter {
    opacity: 0;
  }

  .document-enter-active {
    opacity: 1;
    transition: opacity 400ms ease-in;
  }

  .document-exit {
    opacity: 1;
  }

  .document-exit-active {
    opacity: 0;
    transition: opacity 400ms ease-in;
  }

  table td p[class^='c'] {
    width: 100%;
    margin-right: 0;
  }

  table {
    margin-left: 0;
  }

  p[class^='c'] {
    margin: 0;
  }

  div.doc-content {
    padding: 0 12px;
  }
`

export default documentStyles
