import React from 'react'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { Loader } from '../Loader'
import { useAppContext } from '../../contexts/AppContext'
import { FieldValues, FormProvider, useForm } from 'react-hook-form'
import { PersonInfo } from '../types'
import moment from 'moment'
import { InputFormWidget } from '../widgets/InputWidget/InputFormWidget'
import { DatePickerFormWidget } from '../widgets/DatePickerWidget/DatePickerFormWidget'

interface PersonalDataAuthProps {
  promptInfo: {
    message: string
  }
  onLogin: (data: PersonInfo) => void
}

const YourInformation: React.FC<PersonalDataAuthProps> = ({ promptInfo, onLogin }) => {
  const formMethods = useForm({ reValidateMode: 'onChange' })
  const { handleSubmit } = formMethods

  const { loading } = useAppContext()
  // Specify the minimum date
  const minDate = moment('1900-01-01')
  // Specify the maximum date (current date)
  const maxDate = moment()

  const onSubmit = (data: FieldValues) => {
    let info: PersonInfo | null = {}
    Object.assign(info, data)
    onLogin(info)
  }

  return (
    <Container>
      <Grid container justifyContent='center'>
        <Grid item xs={12} sm={8} md={8} gap={15}>
          <Card>
            <CardContent>
              <Typography variant='h6' align='center'>
                Your Information
              </Typography>
              <div style={{ textAlign: 'center' }}>
                <p
                  style={{ margin: '0.6em 0' }}
                  dangerouslySetInnerHTML={{ __html: promptInfo.message }}
                ></p>
              </div>
              <FormProvider {...formMethods}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <form onSubmit={handleSubmit(onSubmit)} noValidate className='personalDataForm'>
                    <InputFormWidget
                      props={{
                        type: 'text',
                        fieldId: 'firstName',
                        fullWidth: false,
                        label: 'First Name',
                        isDocumentWidget: false,
                        required: true,
                        disabled: loading,
                        style: { height: '56px', width: '100%' },
                      }}
                    />
                    <InputFormWidget
                      props={{
                        type: 'text',
                        fieldId: 'middleName',
                        fullWidth: false,
                        label: 'Middle Name',
                        isDocumentWidget: false,
                        required: false,
                        disabled: loading,
                        style: { height: '56px', width: '100%' },
                      }}
                    />
                    <InputFormWidget
                      props={{
                        type: 'text',
                        fieldId: 'lastName',
                        fullWidth: false,
                        label: 'Last Name',
                        isDocumentWidget: false,
                        required: true,
                        disabled: loading,
                        style: { height: '56px', width: '100%' },
                      }}
                    />
                    <DatePickerFormWidget
                      props={{
                        style: { height: '56px', width: '100%' },
                        type: 'datepicker',
                        fieldId: 'DOB',
                        label: 'Date of birth',
                        required: true,
                        isDocumentWidget: false,
                        fullWidth: false,
                        min_date: minDate,
                        max_date: maxDate,
                      }}
                    />
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      style={{ width: '100%' }}
                      disabled={loading}
                    >
                      Submit {loading && <Loader />}
                    </Button>
                  </form>
                </div>
              </FormProvider>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
}

export default YourInformation
