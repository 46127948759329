import React from 'react'
import { useFormContext } from 'react-hook-form'
import { Box, useTheme } from '@mui/material'

import { SignaturePlaceholderProps } from '../@types/SignatureWidget.types'
import { determineColorProperty } from '../../../Theme'
import { getDimensionInUnits } from '../../../utils/stylesHelper'
import { Role } from '../../../models/Role'

// TODO:
// remove all thesemultiple call functions to determine color

export const SignaturePlaceholder: React.FC<SignaturePlaceholderProps> = (field) => {
  const {
    signatureProps: { label, fieldId, required, style, role },
    handleClickOpen,
    openSignatureModal,
    fieldState
  } = field
  const fallbackWidth = 200
  const fallbackHeight = 80
  const { getValues } = useFormContext()
  const signature = getValues(fieldId)
  const theme = useTheme()

  return (
    <Box
      onClick={handleClickOpen}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        width: getDimensionInUnits('px', fallbackWidth, style?.width),
        height: getDimensionInUnits('px', fallbackHeight, style?.height),
        border: '1px solid',
        borderRadius: '4px',
        borderColor: determineColorProperty(
          role as Role,
          !!fieldState?.error,
          theme,
          'borderColor'
        ),
        backgroundColor: determineColorProperty(role as Role, false, theme, 'bgColor'),
        boxSizing: 'border-box',
        outlineColor: openSignatureModal
          ? determineColorProperty(role as Role, !!fieldState?.error, theme, 'borderColor')
          : 'transparent',
        outlineWidth: '1px',
        outlineStyle: 'solid',
        marginBottom: '2px',
      }}
    >
      {signature ? (
        <img
          src={signature}
          alt={label}
          style={{
            width: getDimensionInUnits('px', fallbackWidth, style?.width),
            height: getDimensionInUnits('px', fallbackHeight, style?.height),
            display: 'block',
            objectFit: 'contain',
          }}
        />
      ) : (
        <p
          style={{
            fontSize: 14,
            fontFamily: 'Times, serif',
            color: determineColorProperty(role as Role, false, theme, 'borderColor'),
            textTransform: 'uppercase',
          }}
        >
          {label}
          {required && '*'}
        </p>
      )}
    </Box>
  )
}
