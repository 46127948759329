import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Loader } from "../Loader";
import { useAppContext } from "../../contexts/AppContext";

import "./VerificationCode.css";
import numberInputStyles from "./VerificationCodeStyles";
import { Global } from "@emotion/react";

interface SendVerificationCodeProps {
    phoneNumberLast4:string
  onLogin: (sendCode: boolean) => void;
}

export const SendVerificationCode: React.FC<SendVerificationCodeProps> = ({
    phoneNumberLast4,
  onLogin,
}) => {
  const { loading } = useAppContext();

  return (
    <Container>
      <Global styles={numberInputStyles} />
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={8}>
          <Card>
            <CardContent>
              <Typography variant="h6" align="center">
                Verification step
              </Typography>
              <div style={{ textAlign: "center" }}>
                <p>
                  Would you like to receive an sms on <br />{" "}
                  {phoneNumberLast4} to verify your identity?
                </p>
              </div>
              <Button
                type="button"
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "1rem" }}
                onClick={() => onLogin(true)}
                disabled={loading}
              >
                Send code {loading && <Loader />}
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};
